import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';

import {
    Chart,
    ArgumentAxis,
    ValueAxis,
    BarSeries,
    Legend
} from '@devexpress/dx-react-chart-material-ui';
import { Animation, ArgumentScale, Stack } from '@devexpress/dx-react-chart';
import cs from '../../const.js';

import Paper from '@material-ui/core/Paper';
import { PagingState, SortingState, CustomPaging } from '@devexpress/dx-react-grid';
import moment from 'moment-timezone';

import React from 'react';
import {
    Grid,
    Table,
    VirtualTable,
    TableHeaderRow,
    PagingPanel
} from '@devexpress/dx-react-grid-material-ui';
import { blue } from '@material-ui/core/colors';

const productsURL = cs.BaseURL + '/product/list';
const URL = cs.BaseURL + '/report/daily_summary';

function formatGridValue(value) {
    if (value && value.report_date)
        value.report_date = moment
            .unix(value.report_date)
            .tz('Asia/Ho_Chi_Minh')
            .format('DD/MM/YYYY');
    if (value.doc_mc_approved_qty == 0 || value.doc_mc_approved_qty == '0') value.doc_mc_approved_qty = 0;
    if (value.doc_on_delivery_to_hub_qty == 0 || value.doc_on_delivery_to_hub_qty == '0') value.doc_on_delivery_to_hub_qty = 0;
    if (value.doc_hub_in_review_qty == 0 || value.doc_hub_in_review_qty == '0') value.doc_hub_in_review_qty = 0;
    if (value.doc_hub_approved_qty == 0 || value.doc_hub_approved_qty == '0') value.doc_hub_approved_qty = 0;
    if (value.doc_hub_sup_req_qty == 0 || value.doc_hub_sup_req_qty == '0') value.doc_hub_sup_req_qty = 0;
    if (value.doc_on_delivery_to_bank_qty == 0 || value.doc_on_delivery_to_bank_qty == '0') value.doc_on_delivery_to_bank_qty = 0;
    if (value.doc_bank_in_review_qty == 0 || value.doc_bank_in_review_qty == '0') value.doc_bank_in_review_qty = 0;
    if (value.doc_bank_approved_qty == 0 || value.doc_bank_approved_qty == '0') value.doc_bank_approved_qty = 0;
    if (value.doc_bank_sup_req_qty == 0 || value.doc_bank_sup_req_qty == '0') value.doc_bank_sup_req_qty = 0;
    return value;
}

function formatChartValue(value) {
    if (value && value.report_date)
        value.report_date = moment
            .unix(value.report_date)
            .tz('Asia/Ho_Chi_Minh')
            .format('DD/MM');

    return value;
}

class DailySummaryByDate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            products: [],
            chartData: [],

            grid2Data: [],
            grid2Columns: [
                { name: 'report_date', title: 'Ngày' },
                { name: 'doc_mc_approved_qty', title: 'Hồ sơ DSA chưa bàn giao' },
                { name: 'doc_on_delivery_to_hub_qty', title: 'Hồ sơ DSA đang chuyển về' },
                { name: 'doc_hub_in_review_qty', title: 'Hồ sơ DSA đã bàn giao' },
                { name: 'doc_hub_approved_qty', title: 'Hồ sơ đầy đủ' },
                { name: 'doc_hub_sup_req_qty', title: 'Hồ sơ chưa đầy đủ' },
                { name: 'doc_on_delivery_to_bank_qty', title: 'Hồ sơ đang chuyển đến Bank' },
                { name: 'doc_bank_in_review_qty', title: 'Hồ sơ đã bàn giao cho Bank' },
                { name: 'doc_bank_approved_qty', title: 'Hồ sơ Bank đánh giá đạt' },
                { name: 'doc_bank_sup_req_qty', title: 'Hồ sơ Bank đánh giá thiếu' },
            ],
            grid2ColumnConfig: [
                { columnName: 'report_date', align: 'center', width: 110 },
                { columnName: 'doc_mc_approved_qty', align: 'center', width: 200 },
                { columnName: 'doc_on_delivery_to_hub_qty', align: 'center', width: 200 },
                { columnName: 'doc_hub_in_review_qty', align: 'center', width: 200 },
                { columnName: 'doc_hub_approved_qty', align: 'center', width: 200 },
                { columnName: 'doc_hub_sup_req_qty', align: 'center', width: 200 },
                { columnName: 'doc_on_delivery_to_bank_qty', align: 'center', width: 200 },
                { columnName: 'doc_bank_in_review_qty', align: 'center', width: 200 },
                { columnName: 'doc_bank_approved_qty', align: 'center', width: 200 },
                { columnName: 'doc_bank_sup_req_qty', align: 'center', width: 200 },
            ],
            grid2TotalCount: 0,
            grid2CurrentPage: 0,

            pageSize: 15,
            loading: true,
            search_date_start: moment()
                .tz('Asia/Ho_Chi_Minh')
                .startOf('day')
                .format('YYYY-MM-DD'),

            search_date_end: moment()
                .tz('Asia/Ho_Chi_Minh')
                .endOf('day')
                .format('YYYY-MM-DD')
        };
        this.changeGrid2CurrentPage = this.changeGrid2CurrentPage.bind(this);
        this.loadProducts = this.loadProducts.bind(this);
        this.loadProducts();
    }

    componentDidMount() {
        this.loadData();
        this.intervalID = setInterval(this.loadData.bind(this), 1000 * 30);
    }
    componentWillUnmount() {
        clearInterval(this.intervalID);
    }
    loadProducts() {
        let queryString = `${productsURL}`;

        fetch(queryString, {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: localStorage.getItem(cs.System_Code + '-doc-token')
            }
        })
            .then(response => response.json())
            .then(data => {
                if (data && data.data) {
                    var products = [];
                    products.push({ code: null, name: 'Tất cả' });
                    data.data.map(p => {
                        const found = products.some(el => el.code === p.code);
                        if (found) return;
                        products.push(p);
                    });

                    this.setState({
                        products: products,
                    });
                } else {
                    this.setState({
                        products: []
                    });

                    if (
                        data &&
                        (data.code == cs.erAuthenticationFailed.code ||
                            data.code == cs.erAccountIsLocked.code)
                    ) {
                        sessionStorage.clear();
                        localStorage.clear();
                        window.location.reload();
                    }
                }
            })
            .catch(() => {
                this.setState({
                    products: []
                });
            });
    }

    changeGrid2CurrentPage(page) {
        this.setState(
            {
                loading: true,
                grid2CurrentPage: page
            },
            () => {
                this.loadGrid1Data();
            }
        );
    }
    queryChartString() {
        const {
            search_date_start,
            search_date_end,
            searchAgent,
            searchHub,
            searchProduct
        } = this.state;

        let queryString = `${URL}?new=true&orderby=report_date`;
        if (searchProduct) queryString = `${queryString}&mc_product_code=${searchProduct}`;
        if (searchAgent) queryString = `${queryString}&agent_code=${searchAgent}`;
        if (searchHub) queryString = `${queryString}&hub_region_id=${searchHub}`

        if (search_date_start)
            queryString = `${queryString}&report_date_from=${moment(search_date_start)
                .startOf('day')
                .unix()}`;
        if (search_date_end)
            queryString = `${queryString}&report_date_to=${moment(search_date_end)
                .endOf('day')
                .unix()}`;

        return queryString;
    }
    loadChartData = conditions => {
        var sum_doc_mc_approved_qty = 0;
        var sum_doc_on_delivery_to_hub_qty = 0;
        var sum_doc_hub_in_review_qty = 0;
        var sum_doc_hub_approved_qty = 0;
        var sum_doc_hub_sup_req_qty = 0;
        var sum_doc_on_delivery_to_bank_qty = 0;
        var sum_doc_bank_in_review_qty = 0;
        var sum_doc_bank_approved_qty = 0;
        var sum_doc_bank_sup_req_qty = 0;

        const queryString = this.queryChartString();
        fetch(queryString, {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: localStorage.getItem(cs.System_Code + '-doc-token')
            }
        })
            .then(response => response.json())
            .then(data => {
                if (data && data.data) {
                    for (var i = 0; i < data.data.length; i++) {
                        data.data[i] = formatGridValue(data.data[i]);
                        sum_doc_mc_approved_qty += parseInt(data.data[i].doc_bank_approved_qty)
                        sum_doc_on_delivery_to_hub_qty += parseInt(data.data[i].doc_on_delivery_to_hub_qty)
                        sum_doc_hub_in_review_qty += parseInt(data.data[i].doc_hub_in_review_qty)
                        sum_doc_hub_approved_qty += parseInt(data.data[i].doc_hub_approved_qty)
                        sum_doc_hub_sup_req_qty += parseInt(data.data[i].doc_hub_sup_req_qty)
                        sum_doc_on_delivery_to_bank_qty += parseInt(data.data[i].doc_on_delivery_to_bank_qty)
                        sum_doc_bank_in_review_qty += parseInt(data.data[i].doc_bank_in_review_qty)
                        sum_doc_bank_approved_qty += parseInt(data.data[i].doc_bank_approved_qty)
                        sum_doc_bank_sup_req_qty += parseInt(data.data[i].doc_bank_sup_req_qty)
                    }

                    this.setState({
                        chartData: [{
                            "report_date": (this.state.search_date_start == this.state.search_date_end ? (this.state.search_date_start) : ('từ ' + this.state.search_date_start + ' đến ' + this.state.search_date_end)),
                            "sum_doc_mc_approved_qty": sum_doc_mc_approved_qty,
                            "sum_doc_on_delivery_to_hub_qty": sum_doc_on_delivery_to_hub_qty,
                            "sum_doc_hub_in_review_qty": sum_doc_hub_in_review_qty,
                            "sum_doc_hub_approved_qty": sum_doc_hub_approved_qty,
                            "sum_doc_hub_sup_req_qty": sum_doc_hub_sup_req_qty,
                            "sum_doc_on_delivery_to_bank_qty": sum_doc_on_delivery_to_bank_qty,
                            "sum_doc_bank_in_review_qty": sum_doc_bank_in_review_qty,
                            "sum_doc_bank_approved_qty": sum_doc_bank_approved_qty,
                            "sum_doc_bank_sup_req_qty": sum_doc_bank_sup_req_qty,
                        }],
                        loading: false
                    });
                } else {
                    this.setState({
                        chartData: [],
                        loading: false
                    });
                }
            })
            .catch(() => this.setState({ loading: false }));
    };

    queryGrid2String() {
        const {
            pageSize,
            grid2CurrentPage,
            search_date_start,
            search_date_end,
            searchAgent,
            searchHub,
            searchProduct
        } = this.state;

        let queryString = `${URL}?pagesize=${pageSize}&page=${grid2CurrentPage +
            1}&orderby=report_date&asc=false&new=true`;
        if (searchProduct) queryString = `${queryString}&mc_product_code=${searchProduct}`;
        if (searchAgent) queryString = `${queryString}&agent_code=${searchAgent}`;
        if (searchHub) queryString = `${queryString}&hub_region_id=${searchHub}`


        if (search_date_start)
            queryString = `${queryString}&report_date_from=${moment(search_date_start)
                .startOf('day')
                .unix()}`;
        if (search_date_end)
            queryString = `${queryString}&report_date_to=${moment(search_date_end)
                .endOf('day')
                .unix()}`;

        return queryString;
    }

    loadGrid2Data = conditions => {
        const queryString = this.queryGrid2String();
        fetch(queryString, {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: localStorage.getItem(cs.System_Code + '-doc-token')
            }
        })
            .then(response => response.json())
            .then(data => {
                console.log(data.data)
                if (data && data.data) {
                    for (var i = 0; i < data.data.length; i++) {
                        data.data[i] = formatGridValue(data.data[i]);
                    }

                    this.setState({
                        grid2Data: data.data,
                        grid2TotalCount: data.total_count,
                        loading: false
                    });
                } else {
                    this.setState({
                        grid2Data: [],
                        grid2TotalCount: 0,
                        loading: false
                    });
                }
            })
            .catch(() => this.setState({ loading: false }));
    };

    loadData = conditions => {
        this.loadChartData();
        this.loadGrid2Data();
    };

    handleChange = name => event => {
        this.setState(
            {
                [name]: event.target.value
            },
            () => {
                this.loadData();
            }
        );
    };

    CellComponent = props => {
        return (
            <Table.Cell
                {...props}
                style={{
                    padding: 2
                }}
            />
        );
    };

    render() {
        const { classes } = this.props;

        const {
            chartData,
            grid2Data,
            grid2Columns,
            grid2ColumnConfig,
            grid2CurrentPage,
            grid2TotalCount,

            pageSize,
            loading
        } = this.state;

        return (
            <div>
                <form className={classes.search_container} noValidate autoComplete="off">
                    <TextField
                        id="search_date_start"
                        label="Từ ngày"
                        className={classes.search_field}
                        value={this.state.search_date_start}
                        onChange={this.handleChange('search_date_start')}
                        type="date"
                        InputLabelProps={{
                            shrink: true
                        }}
                    />

                    <TextField
                        id="search_date_end"
                        label="Đến ngày"
                        className={classes.search_field}
                        value={this.state.search_date_end}
                        onChange={this.handleChange('search_date_end')}
                        type="date"
                        InputLabelProps={{
                            shrink: true
                        }}
                    />

                    <FormControl className={classes.search_field}>
                        <InputLabel htmlFor="hub_region_id">Hub</InputLabel>
                        <Select
                            value={this.state.searchHub}
                            onChange={this.handleChange('searchHub')}
                            inputProps={{
                                name: 'hub_region_id',
                                id: 'hub_region_id'
                            }}
                        >
                            <MenuItem value={1}>1</MenuItem>
                            <MenuItem value={2}>2</MenuItem>
                            <MenuItem value={3}>3</MenuItem>
                        </Select>
                    </FormControl>

                    <FormControl className={classes.search_product_field}>
                        <InputLabel htmlFor="productId">Sản phẩm</InputLabel>
                        <Select
                            value={this.state.searchProduct}
                            onChange={this.handleChange('searchProduct')}
                            inputProps={{
                                name: 'product',
                                id: 'productId'
                            }}
                        >
                            {this.state.products.map(pro => (
                                <MenuItem value={pro.code}>
                                    {pro.code && (
                                        <div>
                                            {pro.mc_code} - {pro.name}
                                        </div>
                                    )}
                                    {!pro.code && <div>{pro.name}</div>}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <TextField
                        id="searchAgent"
                        label="Mã DSA"
                        className={classes.search_field}
                        value={this.state.searchAgent}
                        onChange={this.handleChange('searchAgent')}
                        type="text"
                    />
                </form>
                <Chart data={chartData} className={classes.chart} height="700">
                    <ArgumentAxis />
                    <ArgumentScale />
                    <ValueAxis />
                    <Legend
                        position="bottom"
                        rootComponent={LegendRoot}
                        labelComponent={LegendLabel}
                    />
                    <BarSeries
                        argumentField="report_date"
                        valueField="sum_doc_mc_approved_qty"
                        name="Hồ sơ DSA chưa bàn giao"
                        color="#FF0000"
                    />
                    <BarSeries
                        argumentField="report_date"
                        valueField="sum_doc_on_delivery_to_hub_qty"
                        name="Hồ sơ DSA đang chuyển về"
                        color="#FFC0CB"
                    />
                    <BarSeries
                        argumentField="report_date"
                        valueField="sum_doc_hub_in_review_qty"
                        name="Hồ sơ DSA đã bàn giao"
                        color="#FFFF00"
                    />
                    <BarSeries
                        argumentField="report_date"
                        valueField="sum_doc_hub_approved_qty"
                        name="Hồ sơ đầy đủ"
                        color="#EE82EE"
                    />
                    <BarSeries
                        argumentField="report_date"
                        valueField="sum_doc_hub_sup_req_qty"
                        name="Hồ sơ chưa đầy đủ"
                        color="#00FF00"
                    />
                    <BarSeries
                        argumentField="report_date"
                        valueField="sum_doc_on_delivery_to_bank_qty"
                        name="Hồ sơ đang chuyển đến Bank"
                        color="#00FFFF"
                    />
                    <BarSeries
                        argumentField="report_date"
                        valueField="sum_doc_bank_in_review_qty"
                        name="Hồ sơ đã bàn giao cho Bank"
                        color="#A52A2A"
                    />
                    <BarSeries
                        argumentField="report_date"
                        valueField="sum_doc_bank_approved_qty"
                        name="Hồ sơ Bank đánh giá đạt"
                        color="#00BFFF"
                    />
                    <BarSeries
                        argumentField="report_date"
                        valueField="sum_doc_bank_sup_req_qty"
                        name="Hồ sơ Bank đánh giá thiếu"
                        color="#008000"
                    />
                    <Stack />
                </Chart>
                <Paper className={classes.grid_Container}>
                    <Grid rows={grid2Data} columns={grid2Columns} getRowId={getGrid2RowId}>
                        <PagingState
                            currentPage={grid2CurrentPage}
                            onCurrentPageChange={this.changeGrid2CurrentPage}
                            pageSize={pageSize}
                        />
                        <CustomPaging totalCount={grid2TotalCount} />
                        <VirtualTable
                            tableComponent={TableComponent}
                            columnExtensions={grid2ColumnConfig}
                            rowComponent={RowComponent}
                            cellComponent={this.CellComponent}
                            messages={tableMessages}
                            height="700px"
                        />
                        <TableHeaderRow
                            className={classes.grid_header}
                            rowComponent={HeaderRowComponent}
                            cellComponent={HeaderCellComponent}
                        />
                        <PagingPanel messages={pagingPanelMessages} />
                        {loading && (
                            <CircularProgress
                                style={{
                                    position: 'relative',
                                    left: '50%',
                                    top: '200px'
                                }}
                            />
                        )}
                    </Grid>
                </Paper>
            </div>
        );
    }
}

const rootStyles = {
    display: 'grid',
    gridTemplateColumns: 'auto auto auto',
    margin: 'auto',
};
const LegendRoot = props => <Legend.Root {...props} style={rootStyles} />;

const defaultLabelStyles = {
    marginLeft: '10px',
    whiteSpace: 'nowrap',
    fontSize: '14px'
};
const LegendLabel = props => (
    <div {...props} style={defaultLabelStyles}>
        {props.text}
    </div>
);

const getGrid2RowId = row => row.report_date + row.mc_product_code + row.team_code;
DailySummaryByDate.propTypes = {
    classes: PropTypes.object.isRequired
};

const styles = theme => ({
    grid_Container: {
        width: '100%',
        marginTop: theme.spacing.unit * 2,
        overflowX: 'auto'
    },
    tableStriped: {
        '& tbody tr:nth-of-type(odd)': {
            backgroundColor: 'rgb(186, 207, 255)'
        },
        '& tbody tr:nth-of-type(even)': {
            backgroundColor: 'rgb(217, 226, 244)'
        }
    },
    searchPanel: {
        ...theme.mixins.gutters(),
        paddingTop: theme.spacing.unit,
        paddingBottom: theme.spacing.unit * 2
    },
    search_container: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    search_field: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        marginTop: theme.spacing.unit,
        width: 200,
        minWidth: 120
    },
    search_product_field: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        marginTop: theme.spacing.unit,
        width: 400,
        minWidth: 300
    },
    search_select_menu: {
        width: 200
    },
    grid_header: {
        // backgroundColor: blue
    },
    chart: {
        marginTop: 30
    }
});

const RowComponent = props => {
    return (
        <Table.Row
            {...props}
            style={{
                margin: 1,
                padding: 1,
                height: 40
            }}
        />
    );
};
const HeaderRowComponent = props => {
    return (
        <TableHeaderRow.Row
            {...props}
            style={{
                margin: 1,
                padding: 1,
                height: 46
            }}
        />
    );
};

const HeaderCellComponent = props => {
    return (
        <Table.Cell
            {...props}
            style={{
                color: 'white',
                fontSize: '13px',
                fontWeight: '600',
                backgroundColor: 'rgb(63, 109, 193)',
                margin: 1,
                padding: 1,
                alignContent: 'center'
            }}
        />
    );
};

const tableMessages = {
    noData: 'Không có dữ liệu'
};

const pagingPanelMessages = {
    showAll: 'Hiển thị tất cả',
    rowsPerPage: 'Số dòng mỗi trang',
    info: '{from} đến {to} / Tổng : {count}'
};

const TableComponentBase = ({ classes, ...restProps }) => (
    <VirtualTable.Table {...restProps} className={classes.tableStriped} />
);

export const TableComponent = withStyles(styles, { name: 'TableComponent' })(TableComponentBase);

export default withStyles(styles)(DailySummaryByDate);
