import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import { withToastManager } from 'react-toast-notifications';
import Switch from '@material-ui/core/Switch';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import cs from '../../const.js';
import moment from 'moment-timezone';

const productsURL = cs.BaseURL + '/product/list';
const statesURL = cs.BaseURL + '/address/states';
const districtsURL = cs.BaseURL + '/address/districts';
const wardsURL = cs.BaseURL + '/address/wards';
const orderDetailURL = cs.BaseURL + '/order/detail';
const updateOrderURL = cs.BaseURL + '/order/update';
const styles = theme => ({
    gender_button: {
        marginTop: 20,
        marginLeft: 40
    }
});

class UpdateOrder extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            orderCode: this.props.match.params.orderCode,
            order: null,
            products: [],
            states: [],
            districts: [],
            wards: [],
            mc_product_code: '',
            mc_location_name: '',
            mc_location_adr: '',
            mc_location_contact: '',
            mc_order_ref: '',
            cus_phone: '',
            cus_name: '',
            cus_gender: true,
            cus_adr_state_code: '',
            cus_adr_district_code: '',
            cus_adr_ward_code: '',
            cus_adr_street: ''
        };

        this.loadProducts = this.loadProducts.bind(this);
        this.loadStates = this.loadStates.bind(this);
        this.loadDistricts = this.loadDistricts.bind(this);
        this.loadWards = this.loadWards.bind(this);
        this.handleDialogAgree = this.handleDialogAgree.bind(this);
        this.loadProducts();
        this.loadStates();
        this.loadOrder();
    }

    resetState() {
        this.setState({
            districts: [],
            wards: [],
            order: null,
            mc_product_code: '',
            mc_location_name: '',
            mc_location_adr: '',
            mc_location_contact: '',
            mc_order_ref: '',
            cus_phone: '',
            cus_name: '',
            cus_gender: true,
            cus_adr_state_code: '',
            cus_adr_district_code: '',
            cus_adr_ward_code: '',
            cus_adr_street: ''
        });
    }

    loadOrder = conditions => {
        const { orderCode } = this.state;
        let queryString = `${orderDetailURL}?new=true&order_code=${orderCode}`;
        fetch(queryString, {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: localStorage.getItem(cs.System_Code + '-doc-token')
            }
        })
            .then(response => response.json())
            .then(data => {
                if (data && data.data) {
                    this.setState(
                        {
                            order: data.data,
                            mc_product_code: data.data.mc_product_code,
                            mc_location_name: data.data.mc_location_name,
                            mc_location_adr: data.data.mc_location_adr,
                            mc_location_contact: data.data.mc_location_contact,
                            mc_order_ref: data.data.mc_order_ref,
                            cus_phone: data.data.cus_phone,
                            cus_name: data.data.cus_name,
                            cus_gender: data.data.cus_gender,
                            cus_adr_state_code: data.data.cus_adr_state_code,
                            cus_adr_district_code: data.data.cus_adr_district_code,
                            cus_adr_ward_code: data.data.cus_adr_ward_code,
                            cus_adr_street: data.data.cus_adr_street,
                            loading: false
                        },
                        () => {
                            this.loadStates();
                            this.loadDistricts();
                            this.loadWards();
                        }
                    );
                } else {
                    this.setState({
                        order: null,
                        loading: false
                    });
                    if (
                        data &&
                        (data.code == cs.erAuthenticationFailed.code ||
                            data.code == cs.erAccountIsLocked.code)
                    ) {
                        sessionStorage.clear();
                        localStorage.clear();
                        window.location.reload();
                    }
                }
            })
            .catch(() => this.setState({ loading: false }));
    };

    handleDialogAgree = () => {
        let queryString = `${updateOrderURL}`;

        var body = { order_code: this.state.orderCode };
        if (this.state.order && this.state.order.mc_location_name != this.state.mc_location_name)
            body.mc_location_name = this.state.mc_location_name;
        if (this.state.order && this.state.order.mc_location_adr != this.state.mc_location_adr)
            body.mc_location_adr = this.state.mc_location_adr;
        if (
            this.state.order &&
            this.state.order.mc_location_contact != this.state.mc_location_contact
        )
            body.mc_location_contact = this.state.mc_location_contact;
        if (this.state.order && this.state.order.mc_order_ref != this.state.mc_order_ref)
            body.mc_order_ref = this.state.mc_order_ref;
        if (this.state.order && this.state.order.cus_name != this.state.cus_name)
            body.cus_name = this.state.cus_name;

        var cus_gender = this.state.cus_gender ? 1 : 0;
        if (this.state.order && this.state.order.cus_gender != cus_gender)
            body.cus_gender = cus_gender;
        if (
            this.state.order &&
            (this.state.order.cus_adr_state_code != this.state.cus_adr_state_code ||
                this.state.order.cus_adr_district_code != this.state.cus_adr_district_code ||
                this.state.order.cus_adr_ward_code != this.state.cus_adr_ward_code)
        ) {
            body.cus_adr_state_code = this.state.cus_adr_state_code;
            body.cus_adr_district_code = this.state.cus_adr_district_code;
            body.cus_adr_ward_code = this.state.cus_adr_ward_code;
        }

        if (this.state.order && this.state.order.cus_adr_street != this.state.cus_adr_street)
            body.cus_adr_street = this.state.cus_adr_street;

        fetch(queryString, {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: localStorage.getItem(cs.System_Code + '-doc-token')
            }
        })
            .then(response => response.json())
            .then(data => {
                if (
                    data &&
                    (data.code == cs.erAuthenticationFailed.code ||
                        data.code == cs.erAccountIsLocked.code)
                ) {
                    sessionStorage.clear();
                    window.location.reload();
                }

                if (data && data.error && data.code != cs.Succeed) {
                    this.props.toastManager.add(JSON.stringify(data.error), {
                        appearance: 'error',
                        autoDismiss: true,
                        pauseOnHover: true
                    });
                } else {
                    this.props.toastManager.add('Cập nhật hồ sơ thành công !', {
                        appearance: 'success',
                        autoDismiss: true,
                        pauseOnHover: true
                    });
                }

                if (
                    this.props.history &&
                    this.props.history.location &&
                    this.props.history.location.state &&
                    this.props.history.location.state.previous
                )
                    this.props.history.push({
                        pathname: this.props.history.location.state.previous,
                        state: { reload: true }
                    });

                this.resetState();
            })
            .catch(() => {
                this.resetState();
            });
    };

    loadProducts() {
        let queryString = `${productsURL}`;
        var lastData = JSON.parse(localStorage.getItem(cs.System_Code + '-doc-products'));
        if (
            lastData &&
            queryString === lastData.query &&
            parseInt(lastData.query_at) + 60 * 60 * 1 > moment().unix()
        ) {
            this.setState({ ...lastData });
            return;
        }
        fetch(queryString, {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: localStorage.getItem(cs.System_Code + '-doc-token')
            }
        })
            .then(response => response.json())
            .then(data => {
                if (data && data.data) {
                    this.setState({
                        products: data.data
                    });

                    localStorage.setItem(
                        cs.System_Code + '-doc-products',
                        JSON.stringify({
                            products: data.data,
                            query: queryString,
                            query_at: moment().unix()
                        })
                    );
                } else {
                    this.setState({
                        products: []
                    });
                    localStorage.setItem(cs.System_Code + '-doc-products', null);
                    if (
                        data &&
                        (data.code == cs.erAuthenticationFailed.code ||
                            data.code == cs.erAccountIsLocked.code)
                    ) {
                        sessionStorage.clear();
                        localStorage.clear();
                        window.location.reload();
                    }
                }
            })
            .catch(() => {
                localStorage.setItem(cs.System_Code + '-doc-products', null);
                this.setState({
                    products: []
                });
            });
    }

    loadStates() {
        let queryString = `${statesURL}`;
        var lastData = JSON.parse(localStorage.getItem(cs.System_Code + '-doc-states'));
        if (
            lastData &&
            queryString === lastData.query &&
            parseInt(lastData.query_at) + 60 * 60 * 24 * 30 > moment().unix()
        ) {
            this.setState({ states: lastData.states });
            return;
        }

        fetch(queryString, {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: localStorage.getItem(cs.System_Code + '-doc-token')
            }
        })
            .then(response => response.json())
            .then(data => {
                if (data && data.data) {
                    this.setState({
                        states: data.data
                    });

                    localStorage.setItem(
                        cs.System_Code + '-doc-states',
                        JSON.stringify({
                            states: data.data,
                            query: queryString,
                            query_at: moment().unix()
                        })
                    );
                } else {
                    this.setState({
                        states: []
                    });
                    localStorage.setItem(cs.System_Code + '-doc-states', null);
                    if (
                        data &&
                        (data.code == cs.erAuthenticationFailed.code ||
                            data.code == cs.erAccountIsLocked.code)
                    ) {
                        sessionStorage.clear();
                        localStorage.clear();
                        window.location.reload();
                    }
                }
            })
            .catch(() => {
                localStorage.setItem(cs.System_Code + '-doc-states', null);
                this.setState({
                    states: []
                });
            });
    }

    loadDistricts() {
        let queryString = `${districtsURL}?state_code=${this.state.cus_adr_state_code}`;
        if (queryString === this.lastQueryDistrict) {
            return;
        }

        fetch(queryString, {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: localStorage.getItem(cs.System_Code + '-doc-token')
            }
        })
            .then(response => response.json())
            .then(data => {
                if (data && data.data) {
                    this.setState({
                        districts: data.data
                    });
                    this.lastQueryDistrict = queryString;
                } else {
                    this.setState({
                        districts: []
                    });
                    this.lastQueryDistrict = null;
                    if (
                        data &&
                        (data.code == cs.erAuthenticationFailed.code ||
                            data.code == cs.erAccountIsLocked.code)
                    ) {
                        sessionStorage.clear();
                        localStorage.clear();
                        window.location.reload();
                    }
                }
            })
            .catch(() => {
                this.lastQueryDistrict = null;
            });
    }

    loadWards() {
        let queryString = `${wardsURL}?district_code=${this.state.cus_adr_district_code}`;
        if (queryString === this.lastQueryWard) {
            return;
        }

        fetch(queryString, {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: localStorage.getItem(cs.System_Code + '-doc-token')
            }
        })
            .then(response => response.json())
            .then(data => {
                if (data && data.data) {
                    this.setState({
                        wards: data.data
                    });
                    this.lastQueryWard = queryString;
                } else {
                    this.setState({
                        wards: []
                    });
                    this.lastQueryWard = null;
                    if (
                        data &&
                        (data.code == cs.erAuthenticationFailed.code ||
                            data.code == cs.erAccountIsLocked.code)
                    ) {
                        sessionStorage.clear();
                        localStorage.clear();
                        window.location.reload();
                    }
                }
            })
            .catch(() => {
                this.lastQueryWard = null;
            });
    }

    handleChange = name => event => {
        if (name == 'cus_gender') this.setState({ cus_gender: event.target.checked });
        else
            this.setState(
                {
                    [name]: event.target.value
                },
                () => {
                    if (name == 'cus_adr_state_code') this.loadDistricts();
                    if (name == 'cus_adr_district_code') this.loadWards();
                }
            );
    };

    render() {
        const { classes } = this.props;

        return (
            <div>
                <Dialog open={true} aria-labelledby="form-dialog-title" fullWidth maxWidth="sm">
                    <DialogTitle id="update_order_form-dialog-title">
                        Cập nhật hồ sơ mới
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Chỉ sử dụng tính năng này trong trường hợp cần thiết.
                        </DialogContentText>

                        <TextField
                            disabled
                            margin="dense"
                            id="update_order_mc_product_code"
                            required={true}
                            value={this.state.mc_product_code}
                            label="Sản phẩm"
                            type="text"
                            fullWidth
                        />

                        <TextField
                            autoFocus
                            margin="dense"
                            id="update_order_mc_order_ref"
                            required={true}
                            value={this.state.mc_order_ref}
                            onChange={this.handleChange('mc_order_ref')}
                            label="Chứng từ gốc"
                            type="address"
                            fullWidth
                        />
                        <TextField
                            autoFocus
                            margin="dense"
                            id="update_order_location_name"
                            value={this.state.mc_location_name}
                            onChange={this.handleChange('mc_location_name')}
                            label="Chi nhánh"
                            type="address"
                            fullWidth
                        />
                        <TextField
                            margin="dense"
                            id="update_order_location_contact"
                            value={this.state.mc_location_contact}
                            onChange={this.handleChange('mc_location_contact')}
                            label="Liên hệ hỗ trợ"
                            type="name"
                            fullWidth
                        />

                        <Grid
                            container
                            direction="row"
                            justify="space-between"
                            alignItems="flex-start"
                            spacing={4}
                        >
                            <Grid item xs={6}>
                                <TextField
                                    required={true}
                                    margin="dense"
                                    id="update_order_cus_name"
                                    value={this.state.cus_name}
                                    onChange={this.handleChange('cus_name')}
                                    label="Khách hàng"
                                    type="name"
                                    fullWidth
                                />
                            </Grid>

                            <Grid item xs={6}>
                                <TextField
                                    item
                                    margin="dense"
                                    required={true}
                                    id="update_order_cus_phone"
                                    value={this.state.cus_phone}
                                    onChange={this.handleChange('cus_phone')}
                                    label="Số điện thoại"
                                    type="number"
                                    fullWidth
                                />
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            direction="row"
                            justify="flex-start"
                            alignItems="flex-start"
                            spacing={4}
                        >
                            <Grid item xs={6}>
                                <div fullWidth className={classes.gender_button}>
                                    Giới tính
                                    <Switch
                                        value={this.state.cus_gender}
                                        onChange={this.handleChange('cus_gender')}
                                        color="default"
                                    />
                                    {this.state.cus_gender ? 'Nam' : 'Nữ'}
                                </div>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl fullWidth>
                                    <InputLabel htmlFor="cus_adr_state_id">
                                        Tỉnh/thành phố (*)
                                    </InputLabel>
                                    <Select
                                        value={this.state.cus_adr_state_code}
                                        onChange={this.handleChange('cus_adr_state_code')}
                                        inputProps={{
                                            name: 'cus_adr_state_code',
                                            id: 'cus_adr_state_id'
                                        }}
                                    >
                                        {this.state.states.map(pro => (
                                            <MenuItem value={pro.state_code}>
                                                {pro.state_name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>

                        <Grid
                            container
                            direction="row"
                            justify="space-between"
                            alignItems="flex-start"
                            spacing={4}
                        >
                            <Grid item xs={6}>
                                <FormControl fullWidth>
                                    <InputLabel htmlFor="cus_adr_district_id">
                                        Quận / huyện (*)
                                    </InputLabel>
                                    <Select
                                        value={this.state.cus_adr_district_code}
                                        onChange={this.handleChange('cus_adr_district_code')}
                                        inputProps={{
                                            name: 'cus_adr_district_code',
                                            id: 'cus_adr_district_id'
                                        }}
                                    >
                                        {this.state.districts.map(pro => (
                                            <MenuItem value={pro.district_code}>
                                                {pro.district_name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl fullWidth>
                                    <InputLabel htmlFor="cus_adr_ward_id">Xã/Phường</InputLabel>
                                    <Select
                                        value={this.state.cus_adr_ward_code}
                                        onChange={this.handleChange('cus_adr_ward_code')}
                                        inputProps={{
                                            name: 'cus_adr_ward_code',
                                            id: 'cus_adr_ward_id'
                                        }}
                                    >
                                        {this.state.wards.map(pro => (
                                            <MenuItem value={pro.ward_code}>
                                                {pro.ward_name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>

                        <TextField
                            margin="dense"
                            id="update_order_cus_adr_street"
                            value={this.state.cus_adr_street}
                            onChange={this.handleChange('cus_adr_street')}
                            label="Địa chỉ nhà"
                            type="address"
                            fullWidth
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.props.history.goBack} color="primary">
                            Bỏ qua
                        </Button>
                        <Button onClick={this.handleDialogAgree} color="primary">
                            Cập nhật
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

UpdateOrder.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withToastManager(withStyles(styles)(UpdateOrder));
