import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import cs from '../../const.js';

import Paper from '@material-ui/core/Paper';
import { PagingState, SortingState, CustomPaging, TableColumnVisibility, SelectionState, IntegratedPaging, IntegratedSelection } from '@devexpress/dx-react-grid';
import moment from 'moment-timezone';

import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { Link, withRouter } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import { Grid, Table, VirtualTable, TableHeaderRow, PagingPanel, TableSelection } from '@devexpress/dx-react-grid-material-ui';
import { blue } from '@material-ui/core/colors';
import { ButtonGroup, Checkbox, FormControl, Icon, InputLabel, Select } from '@material-ui/core';
import ReactPDF, { Page, Text, View, Document, StyleSheet, PDFViewer, Font } from '@react-pdf/renderer';


const URL = cs.BaseURL + '/order/list';

function formatValue(value) {
    if (value && value.mc_approval_at)
        value.mc_approval_at_display = moment
            .unix(value.mc_approval_at)
            .tz('Asia/Ho_Chi_Minh')
            .format('DD/MM HH:mm');

    if (value && value.cus_adr_state) value.cus_adr_state = value.cus_adr_state.replace('Thành phố', 'TP').replace('Tỉnh', '');
    if (value && value.cus_adr_district) value.cus_adr_district = value.cus_adr_district.replace('Thành phố', 'TP');

    if (value && value.status) value.status = value.status_desc;

    if (value && value.op_user_fullname) {
        value.op_user_fullname = value.op_user_fullname
            .trim()
            .split(' ')
            .slice(-2)
            .join(' ');
    }
    return value;
}
const isOPUser = () => {
    var user = localStorage.getItem(cs.System_Code + '-doc-user');
    var userrole = JSON.parse(String(user)).role;
    return userrole == cs.Role_OP_User;
};

class HubApproved extends React.Component {
    constructor(props) {
        super(props);

        var username = '';
        var user = localStorage.getItem(cs.System_Code + '-doc-user');
        if (user) username = JSON.parse(String(user)).name;

        this.state = {
            columns: [
                { name: 'mc_approval_at_display', title: 'Kết quả' },
                { name: 'op_user_fullname', title: 'Vận hành' },
                { name: 'agent_name', title: 'Nhân viên' },
                // { name: 'sub_region', title: 'Vùng' },
                { name: 'cus_adr_state', title: 'Tỉnh/TP' },
                { name: 'mc_code', title: 'Đối tác' },
                { name: 'mc_product_name', title: 'Sản phẩm' },
                // { name: 'order_code', title: 'Hồ sơ' },
                { name: 'cus_name', title: 'Khách hàng' },
                // { name: 'cus_phone', title: 'Số ĐT' },
                // { name: 'cus_national_id', title: 'CMND' },
                // { name: 'cus_adr_district', title: 'Quận/Huyện' },
                { name: 'status', title: 'Trạng thái' },
                { name: 'action', title: ' ' }
            ],
            rows: [],
            selection: [],
            tableColumnExtensions: [
                { columnName: 'mc_approval_at_display', align: 'center', width: 110 },
                { columnName: 'op_user_fullname', align: 'center', width: 135 },
                { columnName: "agent_name", align: "left", width: 180 },
                { columnName: 'mc_code', align: 'center', width: 110 },
                { columnName: 'mc_product_name', align: 'left', width: 200 },
                // { columnName: 'order_code', align: 'center', width: 100 },
                { columnName: 'cus_name', align: 'left' },
                { columnName: 'cus_phone', align: 'center', width: 70 },
                // { columnName: 'cus_national_id', align: 'center', width: 120 },
                // { columnName: 'sub_region', align: 'center', width: 60 },
                { columnName: 'cus_adr_state', align: 'center', width: 120 },
                // { columnName: 'cus_adr_district', align: 'center', width: 200 },
                // { columnName: 'agent_name', align: 'center', width: 110 },
                { columnName: 'status', align: 'center', width: 110 },
                { columnName: 'action', align: 'center', width: 90 }
            ],
            pageSizes: [5, 10, 20, 30, 60],
            totalCount: 0,
            pageSize: 30,
            currentPage: 0,
            loading: true,
            sorting: [{ columnName: 'mc_approval_at', direction: 'desc' }],
            searchStatus: '',
            search_op_user_name: isOPUser() ? username : '',

            search_mc_verified_start: moment()
                .tz('Asia/Ho_Chi_Minh')
                .subtract(1, 'month')
                .startOf('day')
                .format('YYYY-MM-DD'),

            search_mc_verified_end: moment()
                .tz('Asia/Ho_Chi_Minh')
                .endOf('day')
                .format('YYYY-MM-DD'),

            showselectioncolumn: false,
            showBackButton: false,
            products: [],
            states: [],
            districts: [],
            stateCheck: undefined
        };

        this.changeCurrentPage = this.changeCurrentPage.bind(this);
        this.changePageSize = this.changePageSize.bind(this);
        // this.changeSorting = this.changeSorting.bind(this);
        this.handleExportRecordsClick = this.handleExportRecordsClick.bind(this);
        this.handlePrintReportClick = this.handlePrintReportClick.bind(this);
        this.handleSelectionState = this.handleSelectionState.bind(this);
        this.handleBackClick = this.handleBackClick.bind(this);
        this.getProductList = this.getProductList.bind(this);
        this.getProductList();
        this.getStateList = this.getStateList.bind(this);
        this.getStateList();
        this.getDistrictList = this.getDistrictList.bind(this);
        this.getDistrictList();
    }
    componentDidMount() {
        this.loadData();
        this.intervalID = setInterval(this.loadData.bind(this), 1000 * 30);
    }

    componentWillUnmount() {
        clearInterval(this.intervalID);
    }

    changeCurrentPage(currentPage) {
        this.setState(
            {
                loading: true,
                currentPage: currentPage
            },
            () => {
                this.loadData();
            }
        );
    }
    changePageSize(pageSize) {
        this.setState(
            {
                loading: true,
                pageSize: pageSize,
                currentPage: 0
            },
            () => {
                this.loadData();
            }
        );
    }
    // changeSorting(sorting) {
    //     this.setState(
    //         {
    //             loading: true,
    //             sorting: sorting
    //         },
    //         () => {
    //             this.loadData();
    //         }
    //     );
    // }

    queryString() {
        const {
            pageSize,
            currentPage,
            sorting,
            searchStatus,
            searchOrderCode,
            searchMerchant,
            searchProduct,
            searchCusName,
            searchCusPhone,
            searchCusNationalID,
            searchAdrState,
            searchAdrDistrict,
            searchAgentName,
            searchMerchantRefCode,
            search_mc_verified_start,
            search_mc_verified_end,
            search_op_user_name
        } = this.state;

        let queryString = `${URL}?pagesize=${pageSize}&page=${currentPage + 1}`;

        const columnSorting = sorting[0];
        if (columnSorting) {
            queryString = `${queryString}&orderby=${columnSorting.columnName}`;

            if (columnSorting.direction === 'desc') queryString = `${queryString}&asc=false`;
            else queryString = `${queryString}&asc=true`;
        }

        if (searchStatus && searchStatus != '') queryString = `${queryString}&status=${searchStatus}`;
        else queryString = `${queryString}&status_group=HubApproved`;

        if (searchOrderCode) queryString = `${queryString}&order_code=${searchOrderCode}`;
        if (searchMerchant) queryString = `${queryString}&mc_code=${searchMerchant}`;
        if (searchProduct) queryString = `${queryString}&mc_product_code=${searchProduct}`;
        if (searchCusName) queryString = `${queryString}&cus_name=${searchCusName}`;
        if (searchCusPhone) queryString = `${queryString}&cus_phone=${searchCusPhone}`;
        if (searchCusNationalID) queryString = `${queryString}&cus_national_id=${searchCusNationalID}`;
        if (searchAdrState) queryString = `${queryString}&cus_adr_state_code=${searchAdrState}`;
        if (searchAdrDistrict) queryString = `${queryString}&cus_adr_district_code=${searchAdrDistrict}`;
        if (searchAgentName) queryString = `${queryString}&agent_name=${searchAgentName}`;
        if (searchMerchantRefCode) queryString = `${queryString}&mc_order_ref=${searchMerchantRefCode}`;
        if (search_mc_verified_start)
            queryString = `${queryString}&mc_verified_start=${moment(search_mc_verified_start)
                .startOf('day')
                .unix()}`;
        if (search_mc_verified_end)
            queryString = `${queryString}&mc_verified_end=${moment(search_mc_verified_end)
                .endOf('day')
                .unix()}`;

        if (search_op_user_name) queryString = `${queryString}&op_user_name=${search_op_user_name}`;
        return queryString;
    }
    loadData = conditions => {
        const queryString = this.queryString();
        // if (this.props.history.location.state && !this.props.history.location.state.reload) {
        //     var lastData = JSON.parse(localStorage.getItem(cs.System_Code + '-op-merchant-orders'));
        //     if (lastData && queryString === lastData.query && parseInt(lastData.query_at) + 60 > moment().unix()) {
        //         this.setState({ ...lastData, loading: false });
        //         return;
        //     }
        // }

        fetch(queryString, {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: localStorage.getItem(cs.System_Code + '-doc-token')
            }
        })
            .then(response => response.json())
            .then(data => {
                if (data && data.data) {
                    for (var i = 0; i < data.data.length; i++) {
                        data.data[i] = formatValue(data.data[i]);
                    }

                    this.setState({
                        rows: data.data,
                        totalCount: data.total_count,
                        loading: false
                    });
                    // localStorage.setItem(
                    //     cs.System_Code + '-op-merchant-orders',
                    //     JSON.stringify({
                    //         rows: data.data,
                    //         totalCount: data.total_count,
                    //         query: queryString,
                    //         query_at: moment().unix()
                    //     })
                    // );
                } else {
                    this.setState({
                        rows: [],
                        totalCount: 0,
                        loading: false
                    });
                    // localStorage.setItem(cs.System_Code + '-op-merchant-orders', null);
                    if (data && (data.code == cs.erAuthenticationFailed.code || data.code == cs.erAccountIsLocked.code)) {
                        sessionStorage.clear();
                        localStorage.clear();
                        window.location.reload();
                    }
                }
            })
            .catch(() => {
                // localStorage.setItem(cs.System_Code + '-op-merchant-orders', null);
                this.setState({ loading: false });
            });
    };

    handleChange = name => event => {
        if (name == 'searchAdrState') {
            this.setState(
                {
                    [name]: event.target.value,
                    stateCheck: event.target.value
                },
                () => {
                    this.state.currentPage = 0;
                    this.loadData();
                    this.getDistrictList();
                }
            );
        }
        this.setState(
            {
                [name]: event.target.value
            },
            () => {
                this.state.currentPage = 0;
                this.loadData();
            }
        );
    };

    getStateList() {
        fetch(cs.BaseURL + '/address/states', {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: localStorage.getItem(cs.System_Code + '-doc-token')
            }
        })
            .then(response => response.json())
            .then((data) => {
                if (data && data.data) {
                    var states = [];
                    data.data.map(s => {
                        states.push(s);
                    });

                    this.setState({
                        states: states,
                    });
                } else {
                    this.setState({
                        states: []
                    });

                    if (
                        data &&
                        (data.code == cs.erAuthenticationFailed.code ||
                            data.code == cs.erAccountIsLocked.code)
                    ) {
                        sessionStorage.clear();
                        localStorage.clear();
                        window.location.reload();
                    }
                }
            })
            .catch(() => {
                this.setState({
                    states: []
                });
            });
    }

    getDistrictList() {
        fetch(cs.BaseURL + `/address/districts?state_code=${this.state.stateCheck}`, {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: localStorage.getItem(cs.System_Code + '-doc-token')
            }
        })
            .then(response => response.json())
            .then((data) => {
                if (data && data.data) {
                    var districts = [];
                    data.data.map(s => {
                        districts.push(s);
                    });

                    this.setState({
                        districts: districts,
                    });
                } else {
                    this.setState({
                        districts: []
                    });

                    if (
                        data &&
                        (data.code == cs.erAuthenticationFailed.code ||
                            data.code == cs.erAccountIsLocked.code)
                    ) {
                        sessionStorage.clear();
                        localStorage.clear();
                        window.location.reload();
                    }
                }
            })
            .catch(() => {
                this.setState({
                    districts: []
                });
            });
    }
    getProductList() {
        fetch(cs.BaseURL + '/product/list', {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: localStorage.getItem(cs.System_Code + '-doc-token')
            }
        })
            .then(response => response.json())
            .then((data) => {
                if (data && data.data) {
                    var products = [];
                    products.push({ code: null, name: 'Tất cả' });
                    data.data.map(p => {
                        const found = products.some(el => el.code === p.code);
                        if (found) return;
                        products.push(p);
                    });

                    this.setState({
                        products: products,
                    });
                } else {
                    this.setState({
                        products: []
                    });

                    if (
                        data &&
                        (data.code == cs.erAuthenticationFailed.code ||
                            data.code == cs.erAccountIsLocked.code)
                    ) {
                        sessionStorage.clear();
                        localStorage.clear();
                        window.location.reload();
                    }
                }
            })
            .catch(() => {
                this.setState({
                    products: []
                });
            });
    }

    handleExportRecordsClick() {
        this.state.showselectioncolumn == true ?
            (
                this.setState({ showselectioncolumn: false })
            ) : (
                this.setState({ showselectioncolumn: true })
            )
    }

    handlePrintReportClick() {
        const RowArray = this.state.rows;
        const CheckArray = this.state.selection;
        const SendArray = [];
        for (var i = 0; i < CheckArray.length; i++) {
            for (var j = 0; j < RowArray.length; j++) {
                if (CheckArray[i] == RowArray[j].id) SendArray.push(RowArray[j]);
            }
        }

        for (var i = 0; i < SendArray.length - 1; i++)
            if (SendArray[i].mc_product_id != SendArray[i + 1].mc_product_id)
                return alert('Lỗi: Tất cả hồ sơ chọn phải có cùng chung một sản phẩm. Vui lòng chọn lại');

        fetch(cs.BaseURL + '/order/file/export', {
            method: 'POST',
            body: JSON.stringify({
                orders: SendArray
            }),
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: localStorage.getItem(cs.System_Code + '-doc-token')
            }
        })
            .then(response => response.json())
            .then((data) => {
                this.setState({ showBackButton: true })
                ReactDOM.render(<this.exportToPDF data={data} SendArray={SendArray} />, document.getElementById('test'));
            })
            .catch(err => console.log(err));
    }

    exportToPDF(props) {
        var today = new Date();
        return (
            <PDFViewer style={styled.pdfviewer}>
                <Document>
                    <Page style={styled.body}>
                        <Text style={styled.header} fixed>{props.data.timestamp}</Text>
                        <Text style={styled.title}>CỘNG HÒA XÃ HỘI CHỦ NGHĨA VIỆT NAM</Text>
                        <Text style={styled.author}>Độc lập - Tự do - Hạnh phúc</Text>
                        <Text style={styled.subtitle}>BIÊN BẢN BÀN GIAO HỒ SƠ SẢN PHẨM {props.SendArray[0].mc_product_name}</Text>
                        <Text style={styled.firstline}>Hôm nay, vào lúc {today.getHours()} giờ {today.getMinutes()} phút, ngày {today.getDate()} tháng {today.getMonth()} năm {today.getFullYear()}, tại ...., Chúng tôi gồm:</Text>
                        <Text style={styled.titlerecive}>BÊN GIAO:</Text>
                        <Text style={styled.text}>Ông/bà:</Text>
                        <Text style={styled.text}>Chứng minh thư nhân dân số:</Text>
                        <Text style={styled.text}>Địa điểm làm việc:</Text>
                        <Text style={styled.text}>Vị trí công việc: </Text>
                        <Text style={styled.titlerecive}>BÊN NHẬN:</Text>
                        <Text style={styled.text}>Ông/bà:</Text>
                        <Text style={styled.text}>Chứng minh thư nhân dân số:</Text>
                        <Text style={styled.text}>Địa điểm làm việc:</Text>
                        <Text style={styled.text}>Vị trí công việc: </Text>
                        <Text style={styled.titlerecive}>Các bên tiến hành giao nhận chứng từ/giấy tờ cụ thể như sau:</Text>
                        <View style={styled.table}>
                            <View style={styled.tableRow}>
                                <View style={styled.tableCol1Header}>
                                    <Text style={styled.tableCellHeader}>STT</Text>
                                </View>
                                <View style={styled.tableColHeader}>
                                    <Text style={styled.tableCellHeader}>Mã hồ sơ</Text>
                                </View>
                                <View style={styled.tableColHeader}>
                                    <Text style={styled.tableCellHeader}>Tên khách hàng</Text>
                                </View>
                                <View style={styled.tableColHeader}>
                                    <Text style={styled.tableCellHeader}>Sản phẩm</Text>
                                </View>
                            </View>
                            {props.SendArray.map((value, index) => {
                                return (
                                    <View style={styled.tableRow} key={index}>
                                        <View style={styled.tableCol1}>
                                            <Text style={styled.tableCell}>{index + 1}</Text>
                                        </View>
                                        <View style={styled.tableCol}>
                                            <Text style={styled.tableCell}>{value.order_code}</Text>
                                        </View>
                                        <View style={styled.tableCol}>
                                            <Text style={styled.tableCell}>{value.cus_name}</Text>
                                        </View>
                                        <View style={styled.tableCol}>
                                            <Text style={styled.tableCell}>{value.mc_product_name}</Text>
                                        </View>
                                    </View>
                                )
                            })}
                        </View>
                        <Text style={styled.alert}>Lưu ý:</Text>
                        <Text style={styled.text}>Tùy vào mục đích giao nhận, hai bên có thể lựa chọn một trong các nội dung sau để thể hiện phần trách nhiệm bảo quản, sử dụng giấy tờ, tài liệu:</Text>
                        <Text style={styled.text}>- Bên nhận có trách nhiệm bảo quản các chứng từ/giấy tờ đã nhận một cách cẩn thận và chỉ sử dụng vào mục đích công việc đã thỏa thuận trong hợp đồng hoặc;</Text>
                        <Text style={styled.text}>- Bên nhận có trách nhiệm bảo quản, sử dụng các chứng từ/giấy tờ đã nhận một cách cẩn thận và đúng quy định đã ban hành</Text>
                        <Text style={styled.text}>Các bên đã giao và nhận các chứng từ/giấy tờ đúng tình trạng nêu trên. Biên bản này gồm ....(...) trang và được lập thành  02 (hai) bản có giá trị như nhau, mỗi người giữ 01 (một) bản.</Text>
                        <Text style={styled.signatureheader}>CHỮ KÝ CỦA CÁC BÊN</Text>
                        <View style={{ flexDirection: 'row' }}>
                            <View style={{ textAlign: 'center', width: '50%' }}>
                                <Text style={styled.signature}>BÊN GIAO</Text>
                                <Text style={styled.signatureunder}>Ký và ghi rõ họ tên</Text>
                            </View>
                            <View style={{ textAlign: 'center', width: '50%' }}>
                                <Text style={styled.signature}>BÊN NHẬN</Text>
                                <Text style={styled.signatureunder}>Ký và ghi rõ họ tên</Text>
                            </View>
                        </View>
                        <Text style={styled.pageNumber} render={({ pageNumber, totalPages }) => (`${pageNumber} / ${totalPages}`)} fixed />
                    </Page>
                </Document>
            </PDFViewer>
        )
    }

    handleBackClick() {
        window.location.reload();
        this.setState({ showBackButton: false });
    }

    handleSelectionState(props) {
        this.setState({
            selection: props
        })
    }

    render() {
        const { classes } = this.props;

        const {
            rows,
            columns,
            tableColumnExtensions,
            pageSizes,
            pageSize,
            currentPage,
            totalCount,
            loading,
            sorting
        } = this.state;

        return (
            <div>
                <ExpansionPanel>
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography className={classes.heading}>Tìm kiếm ...</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <form className={classes.search_container} noValidate autoComplete="off">
                            <TextField
                                id="order_code"
                                label="Hồ sơ"
                                type="search"
                                className={classes.search_field}
                                value={this.state.searchOrderCode}
                                onChange={this.handleChange('searchOrderCode')}
                                margin="normal"
                            />
                            <TextField
                                id="mc_code"
                                label="Đối tác"
                                type="search"
                                className={classes.search_field}
                                value={this.state.searchMerchant}
                                onChange={this.handleChange('searchMerchant')}
                                margin="normal"
                            />
                            <FormControl className={classes.search_field}>
                                <InputLabel htmlFor="productId">Sản phẩm</InputLabel>
                                <Select
                                    value={this.state.searchProduct}
                                    onChange={this.handleChange('searchProduct')}
                                    inputProps={{
                                        name: 'product',
                                        id: 'productId'
                                    }}
                                >
                                    {this.state.products.map(pro => (
                                        <MenuItem value={pro.code}>
                                            {pro.code && (
                                                <div>
                                                    {pro.mc_code} - {pro.name}
                                                </div>
                                            )}
                                            {!pro.code && <div>{pro.name}</div>}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <TextField
                                id="cus_name"
                                label="Khách hàng"
                                type="search"
                                className={classes.search_field}
                                value={this.state.searchCusName}
                                onChange={this.handleChange('searchCusName')}
                                margin="normal"
                            />
                            <TextField
                                id="cus_phone"
                                label="Số đ.thoại"
                                type="search"
                                className={classes.search_field}
                                value={this.state.searchCusPhone}
                                onChange={this.handleChange('searchCusPhone')}
                                margin="normal"
                            />{' '}
                            <TextField
                                id="cus_national_id"
                                label="CMND"
                                type="search"
                                className={classes.search_field}
                                value={this.state.searchCusNationalID}
                                onChange={this.handleChange('searchCusNationalID')}
                                margin="normal"
                            />
                            <FormControl className={classes.search_field}>
                                <InputLabel htmlFor="adr_state">Tỉnh/thành</InputLabel>
                                <Select
                                    value={this.state.searchAdrState}
                                    onChange={this.handleChange('searchAdrState')}
                                    inputProps={{
                                        name: 'state',
                                        id: 'adr_state'
                                    }}
                                >
                                    {this.state.states.map(pro => (
                                        <MenuItem value={pro.state_code}>
                                            {pro.state_code && (
                                                <div>
                                                    {pro.state_name}
                                                </div>
                                            )}
                                            {!pro.state_code && <div>{pro.state_name}</div>}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                            <FormControl className={classes.search_field}>
                                <InputLabel htmlFor="adr_district">Quận / huyện</InputLabel>
                                <Select
                                    value={this.state.searchAdrDistrict}
                                    onChange={this.handleChange('searchAdrDistrict')}
                                    inputProps={{
                                        name: 'district',
                                        id: 'adr_district'
                                    }}
                                >
                                    {this.state.districts.map(pro => (
                                        <MenuItem value={pro.district_code}>
                                            {pro.district_code && (
                                                <div>
                                                    {pro.district_name}
                                                </div>
                                            )}
                                            {!pro.district_code && <div>{pro.district_name}</div>}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <TextField
                                id="agent_name"
                                label="Nhân viên"
                                type="search"
                                className={classes.search_field}
                                value={this.state.searchAgentName}
                                onChange={this.handleChange('searchAgentName')}
                                margin="normal"
                            />
                            <TextField
                                id="mc_order_ref"
                                label="Mã hồ sơ (đối tác)"
                                type="search"
                                className={classes.search_field}
                                value={this.state.searchMerchantRefCode}
                                onChange={this.handleChange('searchMerchantRefCode')}
                                margin="normal"
                            />
                            <TextField
                                id="order_status"
                                select
                                label="Trạng thái"
                                className={classes.search_field}
                                value={this.state.searchStatus}
                                onChange={this.handleChange('searchStatus')}
                                SelectProps={{
                                    MenuProps: {
                                        className: classes.search_select_menu
                                    }
                                }}
                                margin="normal"
                            >
                                <MenuItem value="">
                                    <em />
                                </MenuItem>
                                {/* <MenuItem value={cs.OrderStatus_Ready}>
                                    Hồ sơ mới
                                </MenuItem>
                                <MenuItem value={cs.OrderStatus_AgentAssigned}>
                                    Đã phân công
                                </MenuItem>
                                <MenuItem value={cs.OrderStatus_AgentRejected}>
                                    CTV từ chối
                                </MenuItem>
                                <MenuItem value={cs.OrderStatus_AgentAccepted}>
                                    Đang thực hiện
                                </MenuItem> */}
                                {/* <MenuItem value={cs.OrderStatus_Submitted}>
                                    Đã submit
                                </MenuItem>
                               */}
                                {/* <MenuItem value={cs.OrderStatus_OpApproved}>
                                    OP đã duyệt
                                </MenuItem>
                                <MenuItem value={cs.OrderStatus_OpRejected}>
                                    OP từ chối
                                </MenuItem> */}
                                <MenuItem value={cs.OrderStatus_BankApproved}>Đã duyệt</MenuItem>
                                {/* <MenuItem value={cs.OrderStatus_MerchantRejected}>Đối tác từ chối</MenuItem> */}
                            </TextField>
                            <TextField
                                id="search_mc_verified_start"
                                label="Duyệt từ ngày"
                                className={classes.search_field}
                                value={this.state.search_mc_verified_start}
                                onChange={this.handleChange('search_mc_verified_start')}
                                type="date"
                                InputLabelProps={{
                                    shrink: true
                                }}
                            />
                            <TextField
                                className={classes.search_field}
                                id="search_mc_verified_end"
                                label="Đến ngày"
                                value={this.state.search_mc_verified_end}
                                onChange={this.handleChange('search_mc_verified_end')}
                                type="date"
                                InputLabelProps={{
                                    shrink: true
                                }}
                            />
                            <TextField
                                id="search_op_user_name"
                                label="OP"
                                type="search"
                                className={classes.search_field}
                                value={this.state.search_op_user_name}
                                onChange={this.handleChange('search_op_user_name')}
                                margin="normal"
                            />
                        </form>
                    </ExpansionPanelDetails>
                </ExpansionPanel>

                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Typography style={{ margin: 2, padding: 2 }}>
                        {this.state.showBackButton == true ? (
                            <Button
                                iconStyle={{ height: 200, width: 200, fontSize: '48px' }}
                                className={classes.back_button}
                                aria-label="Quay lại"
                                onClick={this.handleBackClick}
                            >
                                <Icon>arrow_back</Icon> Quay lại
                            </Button>) : null}
                    </Typography>
                    <Typography style={{ margin: 2, padding: 2 }}>
                        {this.state.selection.length > 0 ? (
                            <Button
                                className={classes.print_button}
                                variant="contained"
                                color="primary"
                                onClick={this.handlePrintReportClick}
                            >
                                <Icon>print</Icon>
                                <span style={{ marginLeft: 10 }}>IN BIÊN BẢN</span>
                            </Button>
                        ) : null}

                        <Button
                            className={classes.export_button}
                            variant="contained"
                            color="primary"
                            onClick={this.handleExportRecordsClick}
                        >
                            <Icon>import_export</Icon>
                            <span style={{ marginLeft: 10 }}>XUẤT HỒ SƠ THEO LÔ</span>
                        </Button>
                    </Typography>
                </div>
                <div id="test" style={{ height: 1000 }}>
                    <Paper className={classes.grid_Container}>
                        <Grid rows={rows} columns={columns} getRowId={getRowId}>
                            {/* <SortingState sorting={sorting} onSortingChange={this.changeSorting} /> */}
                            <PagingState
                                currentPage={currentPage}
                                onCurrentPageChange={this.changeCurrentPage}
                                onPageSizeChange={this.changePageSize}
                                pageSize={pageSize}
                            />

                            <SelectionState
                                selection={this.state.selection}
                                onSelectionChange={this.handleSelectionState}
                            />

                            <IntegratedPaging />
                            <IntegratedSelection />

                            <CustomPaging totalCount={totalCount} />

                            <VirtualTable
                                tableComponent={TableComponent}
                                columnExtensions={tableColumnExtensions}
                                cellComponent={CellComponent}
                                rowComponent={RowComponent}
                                messages={tableMessages}
                                height="700px"
                            />

                            <TableHeaderRow
                                // showSortingControls
                                className={classes.grid_header}
                                rowComponent={HeaderRowComponent}
                                cellComponent={HeaderCellComponent}
                            />

                            <TableSelection
                                showSelectAll
                                selectByRowClick
                                highlightRow
                                showSelectionColumn
                            />

                            <PagingPanel pageSizes={pageSizes} messages={pagingPanelMessages} />
                            {loading && (
                                <CircularProgress
                                    style={{
                                        position: 'relative',
                                        left: '50%',
                                        top: '200px'
                                    }}
                                />
                            )}
                        </Grid>
                    </Paper>
                </div>
            </div>
        );
    }
}

const getRowId = row => row.id;
HubApproved.propTypes = {
    classes: PropTypes.object.isRequired
};

Font.register({
    family: 'Roboto',
    src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-medium-webfont.ttf"
});

const BORDER_COLOR = '#bfbfbf'
const BORDER_STYLE = 'solid'
const COL1_WIDTH = 10
const COLN_WIDTH = (100 - COL1_WIDTH) / 3

const styled = StyleSheet.create({
    pdfviewer: {
        width: '100%',
        height: '100%',
    },
    body: {
        paddingBottom: 15,
        paddingHorizontal: 35,
        fontFamily: 'Roboto',
    },
    title: {
        fontSize: 24,
        textAlign: 'center',
    },
    author: {
        fontSize: 16,
        textAlign: 'center',
        marginBottom: 20,
    },
    subtitle: {
        fontSize: 18,
        margin: 12,
        textAlign: 'center',
        marginBottom: 20,
    },
    text: {
        fontSize: 10,
        textAlign: 'justify',
        fontWeight: "normal",
        marginBottom: 5,
    },
    alert: {
        marginTop: 5,
        marginBottom: 5,
        fontWeight: "bold",
        fontSize: 11,
        textDecoration: "underline",
        color: 'red'
    },
    firstline: {
        marginBottom: 5,
        fontWeight: "bold",
        fontSize: 11,
    },
    header: {
        fontSize: 12,
        textAlign: 'center',
        opacity: 0.1,
        marginBottom: 5
    },
    titlerecive: {
        fontSize: 12,
        fontWeight: "bold",
        textDecoration: "underline",
        marginBottom: 5
    },
    signatureheader: {
        fontSize: 12,
        fontWeight: "bold",
        textDecoration: "underline",
        marginBottom: 10,
        textAlign: 'center'
    },
    signature: {
        fontSize: 12,
        fontWeight: "bold",
    },
    signatureunder: {
        fontSize: 10,
    },
    pageNumber: {
        position: 'absolute',
        fontSize: 12,
        bottom: 30,
        left: 0,
        right: 0,
        textAlign: 'center',
        color: 'grey',
    },
    table: {
        display: "table",
        width: "auto",
        borderStyle: BORDER_STYLE,
        borderColor: BORDER_COLOR,
        borderWidth: 1,
        borderRightWidth: 0,
        borderBottomWidth: 0
    },
    tableRow: {
        margin: "auto",
        flexDirection: "row"
    },
    tableCol1Header: {
        width: COL1_WIDTH + '%',
        borderStyle: BORDER_STYLE,
        borderColor: BORDER_COLOR,
        borderBottomColor: '#000',
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0
    },
    tableColHeader: {
        width: COLN_WIDTH + "%",
        borderStyle: BORDER_STYLE,
        borderColor: BORDER_COLOR,
        borderBottomColor: '#000',
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0
    },
    tableCol1: {
        width: COL1_WIDTH + '%',
        borderStyle: BORDER_STYLE,
        borderColor: BORDER_COLOR,
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0,
        textAlign: 'center'
    },
    tableCol: {
        width: COLN_WIDTH + "%",
        borderStyle: BORDER_STYLE,
        borderColor: BORDER_COLOR,
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0
    },
    tableCellHeader: {
        margin: 5,
        fontSize: 12,
        fontWeight: 500,
        textAlign: 'center'
    },
    tableCell: {
        margin: 5,
        fontSize: 10
    },
});

const styles = theme => ({
    grid_Container: {
        width: '100%',
        marginTop: theme.spacing.unit * 2,
        overflowX: 'auto'
    },
    tableStriped: {
        '& tbody tr:nth-of-type(odd)': {
            backgroundColor: 'rgb(186, 207, 255)'
        },
        '& tbody tr:nth-of-type(even)': {
            backgroundColor: 'rgb(217, 226, 244)'
        }
    },
    searchPanel: {
        ...theme.mixins.gutters(),
        paddingTop: theme.spacing.unit,
        paddingBottom: theme.spacing.unit * 2
    },
    search_container: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    search_field: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        marginTop: theme.spacing.unit,
        width: 200,
        minWidth: 120
    },
    search_select_menu: {
        width: 200
    },
    grid_header: {
        //backgroundColor: blue
    },
    export_button: {
        margin: 2,
        padding: 2,
        width: 204,
        height: 34,
        fontSize: 13
    },
    print_button: {
        margin: 2,
        padding: 2,
        height: 34,
        fontSize: 13
    },
    back_button: {
        marginLeft: 0,
        paddingLeft: 0,
        textAlign: 'left'
    },
});

class ActionCell extends React.Component {
    render() {
        return (
            <Table.Cell
                style={{
                    padding: 1,
                    justifyContent: 'center',
                    fontSize: '12px'
                }}
            >
                <span>
                    <Button
                        color="primary"
                        component={Link}
                        to={{ pathname: '/detail/' + this.props.row.order_code, state: { previous: '/hubApproved' } }}
                    >
                        Chi tiết
                    </Button>
                </span>
            </Table.Cell>
        );
    }
}

const CellComponent = props => {
    const { column } = props;
    if (column.name === 'action') {
        return <ActionCell {...props} />;
    }

    var t1 = moment.unix(props.tableRow.row.mc_approval_at);
    var t2 = moment();

    var diff = t2.diff(t1, 'minutes');

    if (diff <= 30)
        return (
            <Table.Cell
                {...props}
                style={{
                    padding: 2,
                    color: '#81557a',
                    fontSize: '12px'
                }}
            />
        );
    else
        return (
            <Table.Cell
                {...props}
                style={{
                    padding: 2,
                    fontSize: '12px'
                }}
            />
        );
};

const RowComponent = props => {

    return (
        <Table.Row
            {...props}
            style={{
                margin: 1,
                padding: 1,
                height: 40
            }}
        />
    );
};
const HeaderRowComponent = props => {
    return (
        <TableHeaderRow.Row
            {...props}
            style={{
                margin: 1,
                padding: 1,
                height: 46
            }}
        />
    );
};

const HeaderCellComponent = props => {
    return (
        <Table.Cell
            {...props}
            style={{
                color: 'white',
                fontSize: '13px',
                fontWeight: '600',
                backgroundColor: 'rgb(63, 109, 193)',
                margin: 1,
                padding: 1,
                // paddingLeft:40,
                alignContent: 'center'
            }}
        />
    );
};

const tableMessages = {
    noData: 'Không có dữ liệu'
};

const pagingPanelMessages = {
    showAll: 'Hiển thị tất cả',
    rowsPerPage: 'Số dòng mỗi trang',
    info: '{from} đến {to} / Tổng : {count}'
};

const TableComponentBase = ({ classes, ...restProps }) => <VirtualTable.Table {...restProps} className={classes.tableStriped} />;

export const TableComponent = withStyles(styles, { name: 'TableComponent' })(TableComponentBase);

export default withRouter(withStyles(styles)(HubApproved));
