import React from 'react';
import Paper from '@material-ui/core/Paper';
import { compose, withProps } from 'recompose';
import { withStyles } from '@material-ui/core/styles';
import cs from '../../const.js';
import 'react-image-gallery/styles/css/image-gallery.css';
import moment from 'moment-timezone';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';

var format = require('string-format');
const URL = cs.BaseURL + '/order/detail';

class OrderOtherInfo extends React.Component {
    constructor(props) {
        super(props);

        this.state = { orderCode: this.props.orderCode, order: null, otherInfos: [] };
    }
    componentDidMount() {
        this.loadData();
        this.intervalID = setInterval(this.loadData.bind(this), 1000 * 30);
    }

    componentWillUnmount() {
        clearInterval(this.intervalID);
    }
    queryString() {
        const { orderCode } = this.state;
        let queryString = `${URL}?order_code=${orderCode}`;
        return queryString;
    }

    loadData = conditions => {
        const queryString = this.queryString();
        fetch(queryString, {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: localStorage.getItem(cs.System_Code + '-doc-token')
            }
        })
            .then(response => response.json())
            .then(data => {
                if (data && data.data) {
                    var order = data.data;
                    var infos = [];
                    if (order) {
                        infos.push(
                            ...[
                                {
                                    caption: 'Nhân viên vận hành',
                                    value: order.op_user_fullname
                                },
                                {
                                    caption: 'Miền',
                                    value: order.region
                                },
                                {
                                    caption: 'Vùng',
                                    value: order.sub_region
                                },
                                {
                                    caption: 'Tạo lúc',
                                    value: moment
                                        .unix(order.created_at)
                                        .tz('Asia/Ho_Chi_Minh')
                                        .format('DD/MM/YYYY HH:mm')
                                },
                                {
                                    caption: 'Tạo bởi',
                                    value: order.created_by
                                },
                                {
                                    caption: 'Kênh tạo',
                                    value: order.created_channel
                                },
                                {
                                    caption: 'Mã gói',
                                    value: order.batch_no
                                },
                                {
                                    caption: 'Đối tác',
                                    value: order.mc_name
                                },
                                {
                                    caption: 'Mã hồ sơ (đối tác)',
                                    value: order.mc_order_ref
                                },
                                {
                                    caption: 'Mã sản phẩm',
                                    value: order.mc_product_code
                                },
                                {
                                    caption: 'Sản phẩm',
                                    value: order.mc_product_name
                                },
                                {
                                    caption: 'DSA',
                                    value: order.agent_name
                                },
                                {
                                    caption: 'Số điện thoại DSA',
                                    value: order.agent_phone
                                },
                                {
                                    caption: 'Phân công lúc',
                                    value: order.agent_assigned_at
                                        ? moment
                                              .unix(order.agent_assigned_at)
                                              .tz('Asia/Ho_Chi_Minh')
                                              .format('DD/MM/YYYY HH:mm')
                                        : ''
                                },
                                {
                                    caption: 'Bắt đầu thực hiện',
                                    value: order.agent_startprocess_at
                                        ? moment
                                              .unix(order.agent_startprocess_at)
                                              .tz('Asia/Ho_Chi_Minh')
                                              .format('DD/MM/YYYY HH:mm')
                                        : ''
                                },
                                {
                                    caption: 'Hẹn gặp khách hàng',
                                    value: order.agent_planning
                                        ? moment
                                              .unix(order.agent_planning)
                                              .tz('Asia/Ho_Chi_Minh')
                                              .format('DD/MM/YYYY HH:mm')
                                        : ''
                                },
                                {
                                    caption: 'Submit hồ sơ',
                                    value: order.submitted_at
                                        ? moment
                                              .unix(order.submitted_at)
                                              .tz('Asia/Ho_Chi_Minh')
                                              .format('DD/MM/YYYY HH:mm')
                                        : ''
                                },
                                {
                                    caption: 'Vận hành phê duyệt',
                                    value: order.op_approval_at
                                        ? moment
                                              .unix(order.op_approval_at)
                                              .tz('Asia/Ho_Chi_Minh')
                                              .format('DD/MM/YYYY HH:mm')
                                        : ''
                                },
                                {
                                    caption: 'Đối tác phê duyệt',
                                    value: order.mc_approval_at
                                        ? moment
                                              .unix(order.mc_approval_at)
                                              .tz('Asia/Ho_Chi_Minh')
                                              .format('DD/MM/YYYY HH:mm')
                                        : ''
                                },
                                {
                                    caption: 'Merchant Approval - Step1 Response Time',
                                    value: order.mc_step1_res_at
                                        ? moment
                                              .unix(order.mc_step1_res_at)
                                              .tz('Asia/Ho_Chi_Minh')
                                              .format('DD/MM/YYYY HH:mm')
                                        : ''
                                },
                                {
                                    caption: 'Merchant Approval - Step1 Status',
                                    value: order.mc_step1_status
                                },
                                {
                                    caption: 'Merchant Approval - Step1 Reason',
                                    value: order.mc_step1_reason
                                },
                                {
                                    caption: 'Merchant Approval - Step2 Response Time',
                                    value: order.mc_step2_res_at
                                        ? moment
                                              .unix(order.mc_step2_res_at)
                                              .tz('Asia/Ho_Chi_Minh')
                                              .format('DD/MM/YYYY HH:mm')
                                        : ''
                                },
                                {
                                    caption: 'Merchant Approval - Step2 Status',
                                    value: order.mc_step2_status
                                },
                                {
                                    caption: 'Merchant Approval - Step2 Reason',
                                    value: order.mc_step2_reason
                                },
                                {
                                    caption: 'Merchant Approval - Step3 Response Time',
                                    value: order.mc_step3_res_at
                                        ? moment
                                              .unix(order.mc_step3_res_at)
                                              .tz('Asia/Ho_Chi_Minh')
                                              .format('DD/MM/YYYY HH:mm')
                                        : ''
                                },
                                {
                                    caption: 'Merchant Approval - Step3 Status',
                                    value: order.mc_step3_status
                                },
                                {
                                    caption: 'Merchant Approval - Step3 Reason',
                                    value: order.mc_step3_reason
                                },
                                {
                                    caption: 'Merchant Approval - Step4 Response Time',
                                    value: order.mc_step4_res_at
                                        ? moment
                                              .unix(order.mc_step4_res_at)
                                              .tz('Asia/Ho_Chi_Minh')
                                              .format('DD/MM/YYYY HH:mm')
                                        : ''
                                },
                                {
                                    caption: 'Merchant Approval - Step4 Status',
                                    value: order.mc_step4_status
                                },
                                {
                                    caption: 'Merchant Approval - Step4 Reason',
                                    value: order.mc_step4_reason
                                }
                            ]
                        );
                    }
                    this.setState({
                        order: data.data,
                        otherInfos: infos,
                        loading: false
                    });
                } else {
                    this.setState({
                        order: null,
                        otherInfos: [],
                        loading: false
                    });
                    if (
                        data &&
                        (data.code == cs.erAuthenticationFailed.code ||
                            data.code == cs.erAccountIsLocked.code)
                    ) {
                        sessionStorage.clear();
                        localStorage.clear();
                        window.location.reload();
                    }
                }
            })
            .catch(() => this.setState({ loading: false }));
    };

    render() {
        const { order, otherInfos } = this.state;
        const { classes } = this.props;

        return (
            <div>
                <List>
                    <ListItem
                        style={{
                            flexGrow: 1,
                            width: '100%',
                            display: 'block'
                        }}
                    >
                        {otherInfos &&
                            otherInfos.map(item => (
                                <div
                                    style={{
                                        width: '100%',
                                        display: 'inline-block'
                                    }}
                                >
                                    <Grid
                                        container
                                        direction="row"
                                        justify="first-start"
                                        alignItems="stretch"
                                        spacing={16}
                                    >
                                        <Grid item xs={3}>
                                            <ListItemText
                                                primary={
                                                    <Typography component="body2">
                                                        {item.caption}
                                                    </Typography>
                                                }
                                                style={{ alignItems: 'left', padding: 0 }}
                                            />
                                        </Grid>

                                        <Grid item xs={9}>
                                            <ListItemText
                                                primary={
                                                    <Typography variant="button">
                                                        {item.value}
                                                    </Typography>
                                                }
                                                style={{
                                                    alignContent: 'left',
                                                    marginTop: 5,
                                                    whiteSpace: 'pre-line'
                                                }}
                                            />
                                        </Grid>
                                    </Grid>

                                    <Divider style={{ margin: '10px 0 10px 0' }} />
                                </div>
                            ))}
                    </ListItem>
                </List>
            </div>
        );
    }
}

const styles = theme => ({
    inner_container: {
        padding: 20
    }
});
export default withStyles(styles)(OrderOtherInfo);
