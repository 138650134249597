import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import { withToastManager } from 'react-toast-notifications';
import Switch from '@material-ui/core/Switch';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import cs from '../../const.js';
import moment from 'moment-timezone';

const changePasswordURL = cs.BaseURL + '/auth/change_password';
const styles = (theme) => ({
    gender_button: {
        marginTop: 20,
        marginLeft: 40
    }
});

class ChangePassword extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            current_password: '',
            new_password: '',
            confirm_password: ''
        };

        this.handleDialogAgree = this.handleDialogAgree.bind(this);
        this.handleDialogDisagree = this.handleDialogDisagree.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }

    resetState() {
        this.setState({
            current_password: '',
            new_password: '',
            confirm_password: ''
        });
    }

    handleClose = () => {
        this.props.onClose();
        this.resetState();
    };

    handleDialogAgree = () => {
        if (
            !this.state.new_password.match(
                /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/
            )
        ) {
            window.alert(
                'Mật khẩu phải bao gồm ít nhất 1 chữ cái viết hoa, 1 chữ cái viết thường, 1 số, 1 kí tự đặc biệt và độ dài từ 8 kí tự trở lên'
            );
            return;
        }

        if (this.state.new_password != this.state.confirm_password) {
            window.alert('Mật khẩu chưa trùng khớp');
            return;
        }

        var username = '';
        var user = localStorage.getItem(cs.System_Code + '-doc-user');
        if (user) username = JSON.parse(String(user)).name;
        let queryString = `${changePasswordURL}`;

        fetch(queryString, {
            method: 'POST',
            body: JSON.stringify({
                old_password: this.state.current_password,
                new_password: this.state.new_password
            }),
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: localStorage.getItem(cs.System_Code + '-doc-token')
            }
        })
            .then((response) => response.json())
            .then((data) => {
                if (
                    data &&
                    (data.code == cs.erAuthenticationFailed.code ||
                        data.code == cs.erAccountIsLocked.code)
                ) {
                    sessionStorage.clear();
                    window.location.reload();
                }

                if (data && data.error && data.code != cs.Succeed) {
                    this.props.toastManager.add(JSON.stringify(data.error), {
                        appearance: 'error',
                        autoDismiss: true,
                        pauseOnHover: true
                    });
                } else {
                    this.props.toastManager.add('Đổi mật khẩu thành công !', {
                        appearance: 'success',
                        autoDismiss: true,
                        pauseOnHover: true
                    });
                }

                this.resetState();
            })
            .catch(() => {
                this.resetState();
            });
        this.handleClose();
    };

    handleDialogDisagree = () => {
        this.handleClose();
    };

    handleChange = (name) => (event) => {
        this.setState({
            [name]: event.target.value
        });
    };

    render() {
        const { classes } = this.props;

        var username = '';
        var user = localStorage.getItem(cs.System_Code + '-doc-user');
        if (user) username = JSON.parse(String(user)).name;
        if (this.props.open != this.state.open && this.props.open) {
            this.state.open = this.props.open;
        }
        return (
            <div>
                <Dialog
                    open={this.props.open}
                    onClose={this.handleClose}
                    aria-labelledby="form-dialog-title"
                    fullWidth
                    maxWidth="sm"
                >
                    <DialogTitle id="create_order_form-dialog-title">Đổi mật khẩu</DialogTitle>
                    <DialogContent>
                        <TextField margin="dense" id="user_name" value={username} fullWidth />
                        <TextField
                            margin="dense"
                            id="current_password"
                            required={true}
                            value={this.state.current_password}
                            onChange={this.handleChange('current_password')}
                            label="Mật khẩu cũ"
                            type="password"
                            fullWidth
                        />
                        <TextField
                            margin="dense"
                            id="new_password"
                            required={true}
                            value={this.state.new_password}
                            onChange={this.handleChange('new_password')}
                            label="Mật khẩu mới"
                            type="password"
                            fullWidth
                        />
                        <TextField
                            autoFocus
                            margin="dense"
                            id="confirm_password"
                            required={true}
                            value={this.state.confirm_password}
                            onChange={this.handleChange('confirm_password')}
                            label="Xác nhận mật khẩu "
                            type="password"
                            fullWidth
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleDialogDisagree} color="primary">
                            Bỏ qua
                        </Button>
                        <Button onClick={this.handleDialogAgree} color="primary">
                            Xác nhận
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

ChangePassword.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withToastManager(withStyles(styles)(ChangePassword));
