import React from 'react';
import { withRouter, Route, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { ToastProvider } from 'react-toast-notifications';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import Switch from '@material-ui/core/Switch';
import Fade from '@material-ui/core/Fade';
import moment from 'moment-timezone';
import format from 'string-format';
import ClipLoader from 'react-spinners/ClipLoader';

import OrderDocList from './OrderDocList';
import OrderActivites from './OrderActivites';
import OrderComments from './OrderComments';

// import OrderMap from './OrderMap';

import OrderFields from './OrderFields';
import OrderRefInfo from './OrderRefInfo';
import OrderOtherInfo from './OrderOtherInfo';
import UpdateOrder from './UpdateOrder';
import cs from '../../const.js';

const URL = cs.BaseURL + '/order/detail';
const cancelURL = cs.BaseURL + '/order/cancel';
const assignAgentURL = cs.BaseURL + '/order/auto-assign-agent';
const assignOpURL = cs.BaseURL + '/order/auto-assign-op';
const approveURL = cs.BaseURL + '/order/approve';
const rejectURL = cs.BaseURL + '/order/reject';
const reopenURL = cs.BaseURL + '/order/reopen';
const closeURL = cs.BaseURL + '/order/close';
const updateMerchantRefCodeURL = cs.BaseURL + '/order/update_mc_ref_code';

const supplementRequestURL = cs.BaseURL + '/order/supplement_request';
const downloadURL = cs.BaseURL + '/order/doc/download';

const receiveFromAgentURL = cs.BaseURL + '/order/receive_from_agent';
const deliveryToBankURL = cs.BaseURL + '/order/delivery_to_bank';
const receiveFromHubURL = cs.BaseURL + '/order/receive_from_hub';
const reApprovalRequestToBankURL = cs.BaseURL + '/order/reapproval_request_to_bank';

function TabContainer(props) {
    return <Paper style={{ padding: 8 * 2, minHeight: 350 }}>{props.children}</Paper>;
}

TabContainer.propTypes = {
    children: PropTypes.node.isRequired
};
class OrderDetail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            orderCode: this.props.match.params.orderCode,
            order: null,
            action_note: '',
            reject_reasons: [],
            supplement_reasons: [],
            cancel_reasons: [],
            supplementRequest_OnUpdateMCRef: true,
            // auto_close: false,
            tabNum: 0,
            modified: false,
            loading: false
        };
        this.handleBackClick = this.handleBackClick.bind(this);
    }
    componentDidMount() {
        this.loadData();
        this.intervalID = setInterval(this.loadData.bind(this), 1000 * 30);
    }

    componentWillUnmount() {
        clearInterval(this.intervalID);
    }
    queryString() {
        const { orderCode } = this.state;
        let queryString = `${URL}?new=true&order_code=${orderCode}`;
        return queryString;
    }

    loadData = (conditions) => {
        const queryString = this.queryString();
        fetch(queryString, {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: localStorage.getItem(cs.System_Code + '-doc-token')
            }
        })
            .then((response) => response.json())
            .then((data) => {
                if (data && data.data) {
                    try {
                        data.data.product.doc_types = JSON.parse(data.data.product.doc_types);
                    } catch (er) {}
                    try {
                        data.data.product.reject_types = JSON.parse(data.data.product.reject_types);
                    } catch (er) {}

                    try {
                        data.data.product.supplement_types = JSON.parse(
                            data.data.product.supplement_types
                        );
                    } catch (er) {}

                    try {
                        data.data.product.cancel_types = JSON.parse(data.data.product.cancel_types);
                    } catch (er) {}

                    this.setState({
                        order: data.data,
                        mc_order_ref: data.data.mc_order_ref,
                        loading: false
                    });
                } else {
                    this.setState({
                        order: null,
                        mc_order_ref: null,
                        loading: false
                    });
                    if (
                        data &&
                        (data.code == cs.erAuthenticationFailed.code ||
                            data.code == cs.erAccountIsLocked.code)
                    ) {
                        sessionStorage.clear();
                        localStorage.clear();
                        window.location.reload();
                    }
                }
            })
            .catch(() => this.setState({ loading: false }));
    };

    handleReceiveFromAgent = () => {
        this.setState(
            {
                action_receive_from_agent: true,
                openDialog: true
            },
            () => {
                this.loadData();
            }
        );
    };

    handleDeliveryToBank = () => {
        this.setState(
            {
                action_delivery_to_bank: true,
                openDialog: true
            },
            () => {
                this.loadData();
            }
        );
    };

    handleReceiveFromHub = () => {
        this.setState(
            {
                action_receive_from_hub: true,
                openDialog: true
            },
            () => {
                this.loadData();
            }
        );
    };

    handleReApprovalRequestToBank = () => {
        this.setState(
            {
                action_reapproval_request_to_bank: true,
                openDialog: true
            },
            () => {
                this.loadData();
            }
        );
    };

    handleCancel = () => {
        var reasons = [];
        this.state.order.product.cancel_types.map((item) => {
            reasons.push({
                value: item.value,
                display: item.display,
                selected: false
            });
        });

        this.setState(
            {
                action_update_ref: false,
                action_supplementRequest: false,
                action_cancel: true,
                action_assign_agent: false,
                action_assign_op: false,
                action_approve: false,
                action_reject: false,
                action_reopen: false,
                action_close: false,
                openDialog: true,
                cancel_reasons: reasons
            },
            () => {
                this.loadData();
            }
        );
    };
    handleAutoAssignAgent = () => {
        this.setState(
            {
                action_update_ref: false,
                action_supplementRequest: false,
                action_assign_agent: true,
                action_assign_op: false,
                action_cancel: false,
                action_approve: false,
                action_reject: false,
                action_reopen: false,
                action_close: false,
                openDialog: true
            },
            () => {
                this.loadData();
            }
        );
    };

    handleAutoAssignOP = () => {
        this.setState(
            {
                action_update_ref: false,
                action_supplementRequest: false,
                action_assign_agent: false,
                action_assign_op: true,
                action_cancel: false,
                action_approve: false,
                action_reject: false,
                action_reopen: false,
                action_close: false,
                openDialog: true
            },
            () => {
                this.loadData();
            }
        );
    };

    handleApprove = () => {
        this.setState(
            {
                action_update_ref: false,
                action_supplementRequest: false,
                action_approve: true,
                action_cancel: false,
                action_assign_agent: false,
                action_assign_op: false,
                action_reject: false,
                action_reopen: false,
                action_close: false,
                openDialog: true
            },
            () => {
                this.loadData();
            }
        );
    };

    handleReopen = () => {
        this.setState(
            {
                action_update_ref: false,
                action_supplementRequest: false,
                action_approve: false,
                action_cancel: false,
                action_assign_agent: false,
                action_assign_op: false,
                action_reject: false,
                action_reopen: true,
                action_close: false,
                openDialog: true
            },
            () => {
                this.loadData();
            }
        );
    };

    handleReject = () => {
        var reasons = [];
        this.state.order.product.reject_types.map((item) => {
            reasons.push({
                value: item.value,
                display: item.display,
                selected: false
            });
        });

        this.setState(
            {
                action_update_ref: false,
                action_supplementRequest: false,
                action_reject: true,
                action_cancel: false,
                action_assign_agent: false,
                action_assign_op: false,
                action_approve: false,
                action_reopen: false,
                action_close: false,
                openDialog: true,
                reject_reasons: reasons
            },
            () => {
                this.loadData();
            }
        );
    };

    handleSupplementRequest = () => {
        var reasons = [];
        this.state.order.product.supplement_types.map((item) => {
            reasons.push({
                value: item.value,
                display: item.display,
                selected: false
            });
        });

        this.setState(
            {
                action_update_ref: false,
                action_supplementRequest: true,
                action_cancel: false,
                action_assign_agent: false,
                action_assign_op: false,
                action_reject: false,
                action_approve: false,
                action_reopen: false,
                action_close: false,
                openDialog: true,
                supplement_reasons: reasons
            },
            () => {
                this.loadData();
            }
        );
    };

    handleCloseOrder = () => {
        this.setState(
            {
                action_update_ref: false,
                action_supplementRequest: false,
                action_cancel: false,
                action_assign_agent: false,
                action_assign_op: false,
                action_reject: false,
                action_approve: false,
                action_reopen: false,
                action_close: true,
                openDialog: true
            },
            () => {
                this.loadData();
            }
        );
    };

    handleDownloadPackage = () => {
        this.downloadOrder();
    };

    handleUpdateMerchantRefCode = () => {
        this.setState(
            {
                action_update_ref: true,
                action_supplementRequest: false,
                action_cancel: false,
                action_assign_agent: false,
                action_assign_op: false,
                action_reject: false,
                action_approve: false,
                action_reopen: false,
                action_close: false,
                openDialog: true
            },
            () => {
                this.loadData();
            }
        );
    };

    handleDialogAgree = () => {
        if (this.state.action_cancel) this.cancelOrder();
        if (this.state.action_assign_agent) this.assignAgent();
        if (this.state.action_assign_op) this.assignOpUser();
        if (this.state.action_approve) this.approveOrder();
        if (this.state.action_reject) this.rejectOrder();
        if (this.state.action_reopen) this.reopenOrder();
        if (this.state.action_close) this.closeOrder();
        if (this.state.action_supplementRequest) this.supplementRequestOrder();
        if (this.state.action_update_ref) this.updateMerchantRefCode();
        if (this.state.action_receive_from_agent) this.receiveFromAgent();
        if (this.state.action_delivery_to_bank) this.deliveryToBank();
        if (this.state.action_receive_from_hub) this.receiveFromHub();
        if (this.state.action_reapproval_request_to_bank) this.reApprovalRequestToBank();
        this.setState({
            openDialog: false
        });
    };
    handleDialogDisagree = () => {
        this.setState({
            openDialog: false
        });
    };

    handleFieldChange = (name) => (event) => {
        // if (name == 'auto_close') this.setState({ auto_close: event.target.checked });
        // else

        if (name == 'supplementRequest_OnUpdateMCRef')
            this.setState({ supplementRequest_OnUpdateMCRef: event.target.checked });
        else
            this.setState(
                {
                    [name]: event.target.value
                },
                () => {}
            );
    };

    handleCancelReasonChange = (reason) => (event) => {
        for (var i = 0; i < this.state.cancel_reasons.length; i++) {
            var item = this.state.cancel_reasons[i];
            if (item.value == reason) item.selected = event.target.checked;
        }

        this.setState(
            {
                cancel_reasons: this.state.cancel_reasons
            },
            () => {}
        );
    };

    handleRejectReasonChange = (reason) => (event) => {
        for (var i = 0; i < this.state.reject_reasons.length; i++) {
            var item = this.state.reject_reasons[i];
            if (item.value == reason) item.selected = event.target.checked;
        }

        this.setState(
            {
                reject_reasons: this.state.reject_reasons
            },
            () => {}
        );
    };

    handleSupplementReasonChange = (reason) => (event) => {
        for (var i = 0; i < this.state.supplement_reasons.length; i++) {
            var item = this.state.supplement_reasons[i];
            if (item.value == reason) item.selected = event.target.checked;
        }

        this.setState(
            {
                supplement_reasons: this.state.supplement_reasons
            },
            () => {}
        );
    };

    handleTabChange = (event, value) => {
        this.setState({ tabNum: value });
    };

    receiveFromAgent = () => {
        const { orderCode } = this.state;
        let queryString = `${receiveFromAgentURL}?order_code=${orderCode}`;

        fetch(queryString, {
            method: 'POST',
            body: JSON.stringify({
                order_code: orderCode
            }),
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: localStorage.getItem(cs.System_Code + '-doc-token')
            }
        })
            .then((response) => response.json())
            .then((data) => {
                this.setState({ action_receive_from_agent: false, modified: true }, () => {
                    this.loadData();
                });
                if (
                    data &&
                    (data.code == cs.erAuthenticationFailed.code ||
                        data.code == cs.erAccountIsLocked.code)
                ) {
                    sessionStorage.clear();
                    window.location.reload();
                } else if (data && data.code == cs.erPermissionDenied.code) {
                    window.alert('Không có quyền Nhận Hồ Sơ !');
                }
            })
            .catch(() =>
                this.setState({ action_receive_from_agent: false }, () => {
                    this.loadData();
                })
            );
    };

    deliveryToBank = () => {
        const { orderCode } = this.state;
        let queryString = `${deliveryToBankURL}?order_code=${orderCode}`;

        fetch(queryString, {
            method: 'POST',
            body: JSON.stringify({
                order_code: orderCode
            }),
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: localStorage.getItem(cs.System_Code + '-doc-token')
            }
        })
            .then((response) => response.json())
            .then((data) => {
                this.setState({ action_delivery_to_bank: false, modified: true }, () => {
                    this.loadData();
                });
                if (
                    data &&
                    (data.code == cs.erAuthenticationFailed.code ||
                        data.code == cs.erAccountIsLocked.code)
                ) {
                    sessionStorage.clear();
                    window.location.reload();
                } else if (data && data.code == cs.erPermissionDenied.code) {
                    window.alert('Không có quyền Gửi Hồ Sơ !');
                }
            })
            .catch(() =>
                this.setState({ action_delivery_to_bank: false }, () => {
                    this.loadData();
                })
            );
    };

    receiveFromHub = () => {
        const { orderCode } = this.state;
        let queryString = `${receiveFromHubURL}?order_code=${orderCode}`;

        fetch(queryString, {
            method: 'POST',
            body: JSON.stringify({
                order_code: orderCode
            }),
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: localStorage.getItem(cs.System_Code + '-doc-token')
            }
        })
            .then((response) => response.json())
            .then((data) => {
                this.setState({ action_receive_from_hub: false, modified: true }, () => {
                    this.loadData();
                });
                if (
                    data &&
                    (data.code == cs.erAuthenticationFailed.code ||
                        data.code == cs.erAccountIsLocked.code)
                ) {
                    sessionStorage.clear();
                    window.location.reload();
                } else if (data && data.code == cs.erPermissionDenied.code) {
                    window.alert('Không có quyền Gửi Hồ Sơ !');
                }
            })
            .catch(() =>
                this.setState({ action_receive_from_hub: false }, () => {
                    this.loadData();
                })
            );
    };

    reApprovalRequestToBank = () => {
        const { orderCode } = this.state;
        let queryString = `${reApprovalRequestToBankURL}?order_code=${orderCode}`;

        fetch(queryString, {
            method: 'POST',
            body: JSON.stringify({
                order_code: orderCode
            }),
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: localStorage.getItem(cs.System_Code + '-doc-token')
            }
        })
            .then((response) => response.json())
            .then((data) => {
                this.setState({ action_reapproval_request_to_bank: false, modified: true }, () => {
                    this.loadData();
                });
                if (
                    data &&
                    (data.code == cs.erAuthenticationFailed.code ||
                        data.code == cs.erAccountIsLocked.code)
                ) {
                    sessionStorage.clear();
                    window.location.reload();
                } else if (data && data.code == cs.erPermissionDenied.code) {
                    window.alert('Không có quyền Xét Duyệt Hồ Sơ !');
                }
            })
            .catch(() =>
                this.setState({ action_reapproval_request_to_bank: false }, () => {
                    this.loadData();
                })
            );
    };

    cancelOrder = () => {
        const { orderCode, cancel_reasons, action_note } = this.state;
        let queryString = `${cancelURL}`;

        var reasons = [];
        for (var i = 0; i < cancel_reasons.length; i++) {
            var item = cancel_reasons[i];
            if (item.selected) reasons.push(item.value);
        }

        fetch(queryString, {
            method: 'POST',
            body: JSON.stringify({
                order_code: orderCode,
                cancel_reasons: reasons,
                action_note: action_note
            }),
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: localStorage.getItem(cs.System_Code + '-doc-token')
            }
        })
            .then((response) => response.json())
            .then((data) => {
                this.setState({ action_cancel: false, modified: true }, () => {
                    this.loadData();
                });
                if (
                    data &&
                    (data.code == cs.erAuthenticationFailed.code ||
                        data.code == cs.erAccountIsLocked.code)
                ) {
                    sessionStorage.clear();
                    window.location.reload();
                } else if (data && data.code == cs.erPermissionDenied.code) {
                    window.alert('Không có quyền Hủy Hồ Sơ !');
                }
            })
            .catch(() =>
                this.setState({ action_cancel: false }, () => {
                    this.loadData();
                })
            );
    };

    assignAgent = () => {
        const { orderCode } = this.state;
        let queryString = `${assignAgentURL}`;

        fetch(queryString, {
            method: 'POST',
            body: JSON.stringify({
                order_code: orderCode
            }),
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: localStorage.getItem(cs.System_Code + '-doc-token')
            }
        })
            .then((response) => response.json())
            .then((data) => {
                this.setState({ action_assign_agent: false, modified: true }, () => {
                    this.loadData();
                });
                if (
                    data &&
                    (data.code == cs.erAuthenticationFailed.code ||
                        data.code == cs.erAccountIsLocked.code)
                ) {
                    sessionStorage.clear();
                    window.location.reload();
                }
            })
            .catch(() =>
                this.setState({ action_assign_agent: false }, () => {
                    this.loadData();
                })
            );
    };

    assignOpUser = () => {
        const { orderCode } = this.state;
        let queryString = `${assignOpURL}`;

        fetch(queryString, {
            method: 'POST',
            body: JSON.stringify({
                order_code: orderCode
            }),
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: localStorage.getItem(cs.System_Code + '-doc-token')
            }
        })
            .then((response) => response.json())
            .then((data) => {
                this.setState({ action_assign_op: false, modified: true }, () => {
                    this.loadData();
                });
                if (
                    data &&
                    (data.code == cs.erAuthenticationFailed.code ||
                        data.code == cs.erAccountIsLocked.code)
                ) {
                    sessionStorage.clear();
                    window.location.reload();
                }
            })
            .catch(() =>
                this.setState({ action_assign_op: false }, () => {
                    this.loadData();
                })
            );
    };

    approveOrder = () => {
        const { orderCode } = this.state;
        let queryString = `${approveURL}?order_code=${orderCode}`;

        fetch(queryString, {
            method: 'POST',
            body: JSON.stringify({
                order_code: orderCode
            }),
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: localStorage.getItem(cs.System_Code + '-doc-token')
            }
        })
            .then((response) => response.json())
            .then((data) => {
                this.setState({ action_approve: false, modified: true }, () => {
                    this.loadData();
                });
                if (
                    data &&
                    (data.code == cs.erAuthenticationFailed.code ||
                        data.code == cs.erAccountIsLocked.code)
                ) {
                    sessionStorage.clear();
                    window.location.reload();
                } else if (data && data.code == cs.erPermissionDenied.code) {
                    window.alert('Không có quyền Phê Duyệt Hồ Sơ !');
                }
            })
            .catch(() =>
                this.setState({ action_approve: false }, () => {
                    this.loadData();
                })
            );
    };

    rejectOrder = () => {
        const { orderCode, action_note, reject_reasons } = this.state;
        let queryString = `${rejectURL}?order_code=${orderCode}`;

        var reasons = [];
        for (var i = 0; i < reject_reasons.length; i++) {
            var item = reject_reasons[i];
            if (item.selected) reasons.push(item.value);
        }

        fetch(queryString, {
            method: 'POST',
            body: JSON.stringify({
                order_code: orderCode,
                reject_reasons: reasons,
                action_note: action_note
                // auto_close: auto_close
            }),
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: localStorage.getItem(cs.System_Code + '-doc-token')
            }
        })
            .then((response) => response.json())
            .then((data) => {
                this.setState({ action_reject: false, modified: true }, () => {
                    this.loadData();
                });
                if (
                    data &&
                    (data.code == cs.erAuthenticationFailed.code ||
                        data.code == cs.erAccountIsLocked.code)
                ) {
                    sessionStorage.clear();
                    window.location.reload();
                } else if (data && data.code == cs.erPermissionDenied.code) {
                    window.alert('Không có quyền Phê Duyệt Hồ Sơ !');
                }
            })
            .catch(() =>
                this.setState({ action_reject: false }, () => {
                    this.loadData();
                })
            );
    };

    closeOrder = () => {
        const { orderCode, action_note } = this.state;
        let queryString = `${closeURL}?order_code=${orderCode}`;

        fetch(queryString, {
            method: 'POST',
            body: JSON.stringify({
                order_code: orderCode,
                action_note: action_note
            }),
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: localStorage.getItem(cs.System_Code + '-doc-token')
            }
        })
            .then((response) => response.json())
            .then((data) => {
                this.setState({ action_close: false, modified: true }, () => {
                    this.loadData();
                });
                if (
                    data &&
                    (data.code == cs.erAuthenticationFailed.code ||
                        data.code == cs.erAccountIsLocked.code)
                ) {
                    sessionStorage.clear();
                    window.location.reload();
                } else if (data && data.code == cs.erPermissionDenied.code) {
                    window.alert('Không có quyền Đóng Hồ Sơ !');
                }
            })
            .catch(() =>
                this.setState({ action_reject: false }, () => {
                    this.loadData();
                })
            );
    };

    reopenOrder = () => {
        const { orderCode, action_note } = this.state;
        let queryString = `${reopenURL}?order_code=${orderCode}`;

        fetch(queryString, {
            method: 'POST',
            body: JSON.stringify({
                order_code: orderCode,
                action_note: action_note
            }),
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: localStorage.getItem(cs.System_Code + '-doc-token')
            }
        })
            .then((response) => response.json())
            .then((data) => {
                this.setState({ action_reopen: false, modified: true }, () => {
                    this.loadData();
                });
                if (
                    data &&
                    (data.code == cs.erAuthenticationFailed.code ||
                        data.code == cs.erAccountIsLocked.code)
                ) {
                    sessionStorage.clear();
                    window.location.reload();
                } else if (data && data.code == cs.erPermissionDenied.code) {
                    window.alert('Không có quyền Mở Lại Hồ Sơ !');
                }
            })
            .catch(() =>
                this.setState({ action_reject: false }, () => {
                    this.loadData();
                })
            );
    };

    supplementRequestOrder = () => {
        const { orderCode, action_note, supplement_reasons } = this.state;
        let queryString = `${supplementRequestURL}?order_code=${orderCode}`;

        var reasons = [];
        for (var i = 0; i < supplement_reasons.length; i++) {
            var item = supplement_reasons[i];
            if (item.selected) reasons.push(item.value);
        }

        fetch(queryString, {
            method: 'POST',
            body: JSON.stringify({
                order_code: orderCode,
                supplement_reasons: reasons,
                action_note: action_note
            }),
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: localStorage.getItem(cs.System_Code + '-doc-token')
            }
        })
            .then((response) => response.json())
            .then((data) => {
                this.setState({ action_supplementRequest: false, modified: true }, () => {
                    this.loadData();
                });
                if (
                    data &&
                    (data.code == cs.erAuthenticationFailed.code ||
                        data.code == cs.erAccountIsLocked.code)
                ) {
                    sessionStorage.clear();
                    window.location.reload();
                } else if (data && data.code == cs.erPermissionDenied.code) {
                    window.alert('Không có thẩm quyền !');
                }
            })
            .catch(() =>
                this.setState({ action_supplementRequest: false }, () => {
                    this.loadData();
                })
            );
    };

    downloadOrder = () => {
        this.setState({ loading: true }, () => {});

        const { order, orderCode } = this.state;
        let queryString = `${downloadURL}?&order_code=${orderCode}`;

        fetch(queryString, {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: localStorage.getItem(cs.System_Code + '-doc-token')
            }
        })
            .then((response) => {
                return response.blob();
            })
            .then((blob) => {
                let url = window.URL.createObjectURL(blob);
                let a = document.createElement('a');
                a.href = url;
                a.download = order.cus_phone + '_' + order.cus_name + '.zip';
                a.click();

                this.setState({ loading: false }, () => {});
            })
            .catch(() => {
                this.setState({ loading: false }, () => {});
            });
    };

    updateMerchantRefCode = () => {
        this.setState({ loading: true }, () => {});

        const { order, mc_order_ref, supplementRequest_OnUpdateMCRef, orderCode } = this.state;
        let queryString = `${updateMerchantRefCodeURL}?&order_code=${orderCode}`;

        fetch(queryString, {
            method: 'POST',
            body: JSON.stringify({
                order_code: orderCode,
                mc_order_ref: mc_order_ref,
                action_supplementRequest: order.op_supplement_requestable
                    ? supplementRequest_OnUpdateMCRef
                    : false
            }),
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: localStorage.getItem(cs.System_Code + '-doc-token')
            }
        })
            .then((response) => response.json())
            .then((data) => {
                this.loadData();

                if (
                    data &&
                    (data.code == cs.erAuthenticationFailed.code ||
                        data.code == cs.erAccountIsLocked.code)
                ) {
                    sessionStorage.clear();
                    window.location.reload();
                } else if (data && data.code == cs.erPermissionDenied.code) {
                    window.alert('Không có thẩm quyền !');
                }
            })
            .catch(() =>
                this.setState({ mc_order_ref: '' }, () => {
                    this.loadData();
                })
            );
    };

    handleBackClick() {
        var { modified } = this.state;
        var previous = this.props.history.location.state.previous;
        this.props.history.push({
            pathname: previous,
            state: { reload: modified }
        });
    }
    render() {
        const { order } = this.state;
        const { classes } = this.props;

        if (this.props.history.location.state && this.props.history.location.state.reload) {
            this.loadData();
            this.props.history.location.state.reload = false;
        }

        var img_logo = order && order.product.img_logo ? order.product.img_logo : null;
        if (String(img_logo).startsWith('aidriven/data'))
            img_logo = String(img_logo).substr(
                String('aidriven/data').length,
                String(img_logo).length - String('aidriven/data').length
            );

        return (
            <div>
                <div className={classes.loading_center}>
                    <ClipLoader
                        sizeUnit={'px'}
                        size={50}
                        color={'#507fe5'}
                        loading={this.state.loading}
                    />
                </div>
                <Button
                    iconStyle={{ height: 200, width: 200, fontSize: '48px' }}
                    className={classes.back_button}
                    aria-label="Quay lại"
                    onClick={this.handleBackClick}
                >
                    <Icon>arrow_back</Icon> Quay lại
                </Button>

                {order && order.op_editable ? (
                    <Button
                        iconStyle={{ height: 200, width: 200, fontSize: '48px' }}
                        color="primary"
                        component={Link}
                        to={{
                            pathname: '/detail/' + this.state.orderCode + '/edit',
                            state: { previous: '/detail/' + this.state.orderCode }
                        }}
                    >
                        <Icon>edit</Icon> Sửa Hồ sơ
                    </Button>
                ) : (
                    ''
                )}

                {/* {order ? (
                    <Button
                        iconStyle={{ height: 200, width: 200, fontSize: '48px' }}
                        color="primary"
                        onClick={this.handleDownloadPackage}
                    >
                        <Icon>cloud_download</Icon>

                        <span style={{ marginLeft: 10 }}>Tài Liệu</span>
                    </Button>
                ) : (
                    ''
                )} */}
                {order && order.op_can_update_mc_ref_code ? (
                    <Button
                        iconStyle={{ height: 200, width: 200, fontSize: '48px' }}
                        color="primary"
                        onClick={this.handleUpdateMerchantRefCode}
                    >
                        <Icon>update</Icon>

                        <span style={{ marginLeft: 10 }}>Cập Nhật Mã Hồ Sơ</span>
                    </Button>
                ) : (
                    ''
                )}

                <Fade in={order}>
                    <Grid
                        container
                        direction="column"
                        justify="space-between"
                        alignItems="stretch"
                        spacing={12}
                        className={classes.root_container}
                    >
                        <Grid
                            container
                            item
                            direction="row"
                            justify="first-start"
                            alignItems="stretch"
                            spacing={1}
                            className={classes.info_container}
                        >
                            <Grid item xs={2} className={classes.info_grid_item}>
                                <Paper className={classes.product_info}>
                                    <Grid
                                        container
                                        direction="column"
                                        justify="space-between"
                                        alignItems="center"
                                        spacing={24}
                                    >
                                        <img
                                            alt={order ? order.mc_code : ''}
                                            src={
                                                order
                                                    ? 'https://media.aidriven.goldengate.biz.vn/images/' +
                                                      img_logo
                                                    : ''
                                            }
                                            style={
                                                !order || !order.product.img_logo
                                                    ? { visibility: 'hidden' }
                                                    : {}
                                            }
                                            className={classes.product_avatar}
                                        />
                                        <Typography variant="button" className={classes.code}>
                                            {order ? order.mc_product_name : ''}
                                        </Typography>{' '}
                                        <Typography variant="h5" className={classes.code}>
                                            {order ? order.order_code : ''}
                                        </Typography>
                                        <Typography variant="caption">
                                            {order
                                                ? moment
                                                      .unix(order.created_at)
                                                      .tz('Asia/Ho_Chi_Minh')
                                                      .format('DD/MM/YYYY')
                                                : ''}
                                        </Typography>
                                    </Grid>
                                </Paper>
                            </Grid>

                            <Grid item xs={7} className={classes.info_grid_item}>
                                <Paper className={classes.cus_info}>
                                    <Grid container spacing={24}>
                                        <Grid item xs={2}>
                                            <Typography component="body2">Họ và tên</Typography>
                                        </Grid>
                                        <Grid item xs={5}>
                                            <Typography variant="button">
                                                {order ? order.cus_name : ''}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Typography component="body2">Ngày sinh</Typography>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <Typography variant="button" align="right">
                                                {order && order.cus_birthday
                                                    ? moment
                                                          .unix(order.cus_birthday)
                                                          .tz('Asia/Ho_Chi_Minh')
                                                          .format('DD-MM-YYYY')
                                                    : ''}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <Typography component="body2">Giới tính</Typography>
                                        </Grid>
                                        <Grid item xs={5}>
                                            <Typography variant="subtitle2">
                                                {order && order.cus_gender ? 'Nam' : 'Nữ'}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Typography component="body2">Số điện thoại</Typography>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <Typography variant="button" align="right">
                                                {order ? order.cus_phone : ''}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <Typography component="body2">Địa chỉ</Typography>
                                        </Grid>
                                        <Grid item xs={10}>
                                            <Typography variant="caption">
                                                {order ? order.cus_adr_full_address : ''}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <Typography component="body2">Phụ trách</Typography>
                                        </Grid>
                                        <Grid item xs={5}>
                                            <Typography variant="button">
                                                {order ? order.agent_name : ''}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Typography component="body2">Số điện thoại</Typography>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <Typography variant="button" align="right">
                                                {order ? order.agent_phone : ''}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>

                            <Grid item xs={3} className={classes.info_grid_item}>
                                <Paper className={classes.status_info}>
                                    <Grid
                                        container
                                        direction="column"
                                        justify="space-between"
                                        alignItems="center"
                                        style={{ padding: 1 }}
                                        spacing={24}
                                    >
                                        <Typography component="body2">
                                            {order && order.closed
                                                ? 'Hồ sơ đã đóng !'
                                                : 'Trạng thái'}
                                        </Typography>
                                        <Typography variant="h5">
                                            {order ? order.status_desc : ''}
                                        </Typography>
                                        <Grid
                                            item
                                            container
                                            direction="row"
                                            justify="center"
                                            alignItems="center"
                                            className={classes.button_container}
                                        >
                                            {/* {order && order.op_cancelable ? (
                                                <Button
                                                    className={classes.approval_button}
                                                    variant="contained"
                                                    color="secondary"
                                                    onClick={this.handleCancel}
                                                >
                                                    HUỶ HỒ SƠ
                                                </Button>
                                            ) : (
                                                ''
                                            )}

                                            {order && order.op_can_assign_agent ? (
                                                <Button
                                                    className={classes.approval_button}
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={this.handleAutoAssignAgent}
                                                >
                                                    PHÂN CÔNG DSA KHÁC
                                                </Button>
                                            ) : (
                                                ''
                                            )}

                                            {order && order.op_can_assign_op ? (
                                                <Button
                                                    className={classes.approval_button}
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={this.handleAutoAssignOP}
                                                >
                                                    PHÂN CÔNG OP KHÁC
                                                </Button>
                                            ) : (
                                                ''
                                            )}

                                            {order && order.op_approvable ? (
                                                <Button
                                                    className={classes.approval_button}
                                                    variant="contained"
                                                    color="secondary"
                                                    onClick={this.handleReject}
                                                >
                                                    TỪ CHỐI
                                                </Button>
                                            ) : (
                                                ''
                                            )} */}

                                            {order && order.order_dochub_can_receive_from_agent && (
                                                <Button
                                                    className={classes.approval_button}
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={this.handleReceiveFromAgent}
                                                >
                                                    NHẬN HỒ SƠ TỪ DSA
                                                </Button>
                                            )}

                                            {order && order.order_dochub_can_delivery_to_bank && (
                                                <Button
                                                    className={classes.approval_button}
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={this.handleDeliveryToBank}
                                                >
                                                    GỬI HỒ SƠ ĐẾN BANK
                                                </Button>
                                            )}

                                            {order && order.order_docbank_can_receive_from_hub && (
                                                <Button
                                                    className={classes.approval_button}
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={this.handleReceiveFromHub}
                                                >
                                                    NHẬN HỒ SƠ TỪ HUB
                                                </Button>
                                            )}

                                            {(order && order.order_dochub_can_approvable) ||
                                            (order && order.order_docbank_can_approvable) ? (
                                                <Button
                                                    className={classes.approval_button}
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={this.handleApprove}
                                                >
                                                    PHÊ DUYỆT
                                                </Button>
                                            ) : (
                                                ''
                                            )}

                                            {order &&
                                            order.order_dochub_can_reapproval_request_to_bank ? (
                                                <Button
                                                    className={classes.approval_button}
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={this.handleReApprovalRequestToBank}
                                                >
                                                    BANK XÉT DUYỆT LẠI
                                                </Button>
                                            ) : (
                                                ''
                                            )}

                                            {/* {order && order.op_reopenable ? (
                                                <Button
                                                    className={classes.approval_button}
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={this.handleReopen}
                                                >
                                                    MỞ LẠI HỒ SƠ
                                                </Button>
                                            ) : (
                                                ''
                                            )} */}

                                            {(order &&
                                                order.order_dochub_can_supplement_requestable) ||
                                            (order &&
                                                order.order_docbank_can_supplement_requestable) ? (
                                                <Button
                                                    className={classes.approval_button}
                                                    variant="contained"
                                                    color="secondary"
                                                    onClick={this.handleSupplementRequest}
                                                >
                                                    YÊU CẦU BỔ SUNG
                                                </Button>
                                            ) : (
                                                ''
                                            )}

                                            {/* {order &&
                                            order.order_dochub_can_supplement_requestable &&
                                            order.status == 63 ? (
                                                <Button
                                                    className={classes.approval_button}
                                                    variant="contained"
                                                    color="secondary"
                                                    onClick={this.handleSupplementRequest}
                                                >
                                                    YÊU CẦU DSA BỔ SUNG
                                                </Button>
                                            ) : (
                                                ''
                                            )} */}

                                            {/* {order && order.op_closeable ? (
                                                <Button
                                                    className={classes.approval_button}
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={this.handleCloseOrder}
                                                >
                                                    ĐÓNG HỒ SƠ
                                                </Button>
                                            ) : (
                                                ''
                                            )} */}
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>
                        </Grid>
                        <Grid container item direction="column" alignItems="stretch">
                            {/* <Paper className={classes.tab_container}> */}
                            <AppBar position="static">
                                <Tabs value={this.state.tabNum} onChange={this.handleTabChange}>
                                    <Tab label="Thông tin đầu vào" />
                                    <Tab label="Thông tin thu thập" />
                                    <Tab label="Hình ảnh thu thập" />
                                    <Tab label="Lịch sử" />
                                    <Tab label="Bình luận" />
                                    {/* <Tab label="Bản đồ" /> */}
                                    <Tab label="Thông tin thêm" />
                                </Tabs>
                            </AppBar>
                            <div style={{ minHeight: 400 }}>
                                {this.state.tabNum === 0 && (
                                    <TabContainer>
                                        {this.state.order ? (
                                            <OrderRefInfo orderCode={this.state.order.order_code} />
                                        ) : (
                                            ''
                                        )}
                                    </TabContainer>
                                )}
                                {this.state.tabNum === 1 && (
                                    <TabContainer>
                                        {this.state.order ? (
                                            <OrderFields orderCode={this.state.order.order_code} />
                                        ) : (
                                            ''
                                        )}
                                    </TabContainer>
                                )}
                                {this.state.tabNum === 2 && (
                                    <TabContainer>
                                        {this.state.order ? (
                                            <OrderDocList orderCode={this.state.order.order_code} />
                                        ) : (
                                            ''
                                        )}
                                    </TabContainer>
                                )}
                                {this.state.tabNum === 3 && (
                                    <TabContainer>
                                        {this.state.order ? (
                                            <OrderActivites
                                                orderCode={this.state.order.order_code}
                                            />
                                        ) : (
                                            ''
                                        )}
                                    </TabContainer>
                                )}
                                {this.state.tabNum === 4 && (
                                    <TabContainer>
                                        {this.state.order ? (
                                            <OrderComments
                                                orderCode={this.state.order.order_code}
                                            />
                                        ) : (
                                            ''
                                        )}
                                    </TabContainer>
                                )}
                                {/* {this.state.tabNum === 4 && (
                                    <TabContainer>
                                        {this.state.order ? <OrderMap orderCode={this.state.order.order_code} /> : ''}
                                    </TabContainer>
                                )} */}
                                {this.state.tabNum === 5 && (
                                    <TabContainer>
                                        {this.state.order ? (
                                            <OrderOtherInfo
                                                orderCode={this.state.order.order_code}
                                            />
                                        ) : (
                                            ''
                                        )}
                                    </TabContainer>
                                )}
                            </div>
                            {/* </Paper> */}
                        </Grid>
                    </Grid>
                </Fade>
                <Dialog
                    open={this.state.openDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {this.state.action_receive_from_agent
                            ? 'Bạn có thực sự muốn Nhận Hồ Sơ ?'
                            : ''}
                        {this.state.action_delivery_to_bank
                            ? 'Bạn có thực sự muốn Gửi Hồ Sơ ?'
                            : ''}
                        {this.state.action_receive_from_hub
                            ? 'Bạn có thực sự muốn Nhận Hồ Sơ ?'
                            : ''}
                        {this.state.action_reapproval_request_to_bank
                            ? 'Bạn có thực sự muốn Xét Duyệt Lại Hồ Sơ'
                            : ''}
                        {this.state.action_cancel ? 'Bạn có thực sự muốn Hủy Hồ Sơ ?' : ''}
                        {this.state.action_approve ? 'Bạn có thực sự muốn Phê Duyệt Hồ Sơ ?' : ''}
                        {this.state.action_reject ? 'Bạn có thực sự muốn Từ Chối Hồ Sơ ?' : ''}
                        {this.state.action_reopen ? 'Bạn có thực sự muốn Mở Lại Hồ Sơ ?' : ''}
                        {this.state.action_close ? 'Bạn có thực sự muốn Đóng Hồ Sơ ?' : ''}
                        {this.state.action_supplementRequest
                            ? 'Bạn có thực sự muốn Yêu Cầu Bổ Sung Hồ Sơ ?'
                            : ''}
                        {this.state.action_update_ref
                            ? 'Cập nhật mã hồ sơ nhận từ hệ thống đối tác : '
                            : ''}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {this.state.order && this.state.action_receive_from_agent
                                ? format(
                                      'Hồ Sơ {0} của {1} sẽ được nhận từ DSA',
                                      this.state.order.order_code,
                                      this.state.order.cus_name
                                  )
                                : ''}
                            {this.state.order && this.state.action_delivery_to_bank
                                ? format(
                                      'Hồ Sơ {0} của {1} sẽ được gửi đến Bank.',
                                      this.state.order.order_code,
                                      this.state.order.cus_name
                                  )
                                : ''}
                            {this.state.order && this.state.action_receive_from_hub
                                ? format(
                                      'Hồ Sơ {0} của {1} sẽ được nhận từ Hub.',
                                      this.state.order.order_code,
                                      this.state.order.cus_name
                                  )
                                : ''}
                            {this.state.order && this.state.action_reapproval_request_to_bank
                                ? format(
                                      'Hồ Sơ {0} của {1} sẽ được xét duyệt lại từ Bank.',
                                      this.state.order.order_code,
                                      this.state.order.cus_name
                                  )
                                : ''}
                            {this.state.order && this.state.action_cancel
                                ? format(
                                      'Hồ Sơ {0} của {1} sẽ bị hủy và không thể khôi phục.',
                                      this.state.order.order_code,
                                      this.state.order.cus_name
                                  )
                                : ''}
                            {this.state.order && this.state.action_approve
                                ? format(
                                      'Hồ Sơ {0} của {1} sẽ được phê duyệt nội bộ.\n Tùy sản phẩm, hồ sơ có thể tự động phê duyệt bởi đối tác ',
                                      this.state.order.order_code,
                                      this.state.order.cus_name
                                  )
                                : ''}
                            {this.state.order && this.state.action_reject
                                ? format(
                                      'Hồ Sơ {0} của {1} sẽ bị từ chối.',
                                      this.state.order.order_code,
                                      this.state.order.cus_name
                                  )
                                : ''}

                            {this.state.order && this.state.action_close
                                ? format(
                                      'Hồ Sơ {0} của {1} sẽ được đóng và chỉ Quản lý Vận Hành mới mở lại được hồ sơ.',
                                      this.state.order.order_code,
                                      this.state.order.cus_name
                                  )
                                : ''}

                            {this.state.order && this.state.action_reopen
                                ? format(
                                      'Hồ Sơ {0} của {1} sẽ được mở lại.',
                                      this.state.order.order_code,
                                      this.state.order.cus_name
                                  )
                                : ''}
                            {this.state.order && this.state.action_supplementRequest
                                ? format(
                                      'Hồ Sơ {0} của {1} sẽ bị yêu cầu bổ sung thông tin/tài liệu.',
                                      this.state.order.order_code,
                                      this.state.order.cus_name
                                  )
                                : ''}

                            {this.state.order &&
                            !this.state.order.agent_name &&
                            this.state.action_assign_agent
                                ? format(
                                      'Hồ Sơ {0} sẽ được phân ngẫu nhiên cho nhân sự trong địa bàn.',
                                      this.state.order.order_code
                                  )
                                : ''}

                            {this.state.order &&
                            this.state.order.agent_name &&
                            this.state.action_assign_agent
                                ? format(
                                      'Hồ Sơ {0} đã được phân công cho {1} nhưng sẽ được phân ngẫu nhiên cho nhân sự khác trong địa bàn.',
                                      this.state.order.order_code,
                                      this.state.order.agent_name
                                  )
                                : ''}

                            {this.state.order &&
                            !this.state.order.op_user_id &&
                            this.state.action_assign_op
                                ? format(
                                      'Hồ Sơ {0} sẽ được phân bổ cho nhân viên vận hành.',
                                      this.state.order.order_code
                                  )
                                : ''}

                            {this.state.order &&
                            this.state.order.op_user_id &&
                            this.state.action_assign_op
                                ? format(
                                      'Hồ Sơ {0} đã được phân công cho {1} nhưng sẽ được phân bổ cho nhân viên vận hành khác.',
                                      this.state.order.order_code,
                                      this.state.order.op_user_fullname
                                  )
                                : ''}
                        </DialogContentText>
                        {this.state.order &&
                        this.state.action_reject &&
                        this.state.order.product.reject_types ? (
                            <div>
                                <FormGroup fullWidth>
                                    {this.state.reject_reasons.map((item, index) => (
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={item.selected}
                                                    onChange={this.handleRejectReasonChange(
                                                        item.value
                                                    )}
                                                    value={item.selected}
                                                />
                                            }
                                            label={index + 1 + '-' + item.display}
                                        />
                                    ))}
                                </FormGroup>
                            </div>
                        ) : (
                            ''
                        )}

                        {this.state.order &&
                        this.state.action_supplementRequest &&
                        this.state.order.product.supplement_types ? (
                            <div>
                                <FormGroup fullWidth>
                                    {this.state.supplement_reasons.map((item, index) => (
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={item.selected}
                                                    onChange={this.handleSupplementReasonChange(
                                                        item.value
                                                    )}
                                                    value={item.selected}
                                                />
                                            }
                                            label={index + 1 + '-' + item.display}
                                        />
                                    ))}
                                </FormGroup>
                            </div>
                        ) : (
                            ''
                        )}

                        {this.state.order &&
                        this.state.action_cancel &&
                        this.state.order.product.cancel_types ? (
                            <div>
                                <FormGroup fullWidth>
                                    {this.state.cancel_reasons.map((item, index) => (
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={item.selected}
                                                    onChange={this.handleCancelReasonChange(
                                                        item.value
                                                    )}
                                                    value={item.selected}
                                                />
                                            }
                                            label={index + 1 + '-' + item.display}
                                        />
                                    ))}
                                </FormGroup>
                            </div>
                        ) : (
                            ''
                        )}

                        {this.state.order &&
                        (this.state.action_cancel ||
                            this.state.action_reject ||
                            this.state.action_reopen ||
                            this.state.action_supplementRequest) ? (
                            <TextField
                                fullWidth
                                margin="dense"
                                id="order_action_note"
                                value={this.state.action_note}
                                onChange={this.handleFieldChange('action_note')}
                                label="Ghi chú"
                                type="name"
                            />
                        ) : (
                            ''
                        )}

                        {/* {this.state.order && this.state.action_reject ? (
                            <label fullWidth>
                                <input
                                    type="checkbox"
                                    className={classes.auto_close}
                                    checked={this.state.auto_close}
                                    value={this.state.auto_close}
                                    onChange={this.handleFieldChange('auto_close')}
                                />
                                ĐÓNG HỒ SƠ
                            </label>
                        ) : (
                            ''
                        )} */}

                        {this.state.order && this.state.action_update_ref ? (
                            <TextField
                                fullWidth
                                margin="dense"
                                id="mc_order_ref"
                                value={this.state.mc_order_ref}
                                onChange={this.handleFieldChange('mc_order_ref')}
                                label="Mã hồ sơ từ đối tác"
                                type="name"
                            />
                        ) : (
                            ''
                        )}

                        {this.state.order &&
                        this.state.action_update_ref &&
                        this.state.order.op_supplement_requestable ? (
                            <label fullWidth>
                                <input
                                    type="checkbox"
                                    className={classes.auto_close}
                                    checked={this.state.supplementRequest_OnUpdateMCRef}
                                    value={this.state.supplementRequest_OnUpdateMCRef}
                                    onChange={this.handleFieldChange(
                                        'supplementRequest_OnUpdateMCRef'
                                    )}
                                />
                                Yêu cầu bổ sung hồ sơ
                            </label>
                        ) : (
                            ''
                        )}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleDialogDisagree} color="primary">
                            Bỏ qua
                        </Button>
                        <Button onClick={this.handleDialogAgree} color="primary" autoFocus>
                            Đồng Ý
                        </Button>
                    </DialogActions>
                </Dialog>

                <ToastProvider autoDismissTimeout={3000} placement="bottom-center">
                    <Route exact path="/detail/:orderCode/edit" component={UpdateOrder} />
                </ToastProvider>
            </div>
        );
    }
}

const styles = (theme) => ({
    root_container: {
        paddingTop: theme.spacing.unit * 1
    },
    info_container: {
        paddingTop: theme.spacing.unit * 1,
        paddingBottom: theme.spacing.unit * 2
    },
    info_grid_item: {
        paddingTop: theme.spacing.unit * 1,
        paddingBottom: theme.spacing.unit * 2
    },
    product_info: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        paddingTop: theme.spacing.unit * 1,
        paddingBottom: theme.spacing.unit * 1,
        height: '100vh',
        maxHeight: 200
    },
    cus_info: {
        display: 'flex',
        flexWrap: 'wrap',
        ...theme.mixins.gutters(),
        paddingTop: theme.spacing.unit * 3,
        paddingBottom: theme.spacing.unit * 3,
        height: '100vh',
        maxHeight: 200
    },
    status_info: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
        paddingTop: 10,
        paddingBottom: theme.spacing.unit * 1,
        height: '100vh',
        maxHeight: 200
    },

    button_container: {
        width: 'auto',
        paddingTop: theme.spacing.unit * 1,
        height: 57
    },
    product_avatar: {
        margin: 10,
        width: 'auto',
        height: 42
    },
    tab_container: {
        padding: 20
    },

    approval_button: {
        margin: 2,
        padding: 2,
        width: 204,
        height: 34,
        fontSize: 13
    },
    back_button: {
        marginLeft: 0,
        paddingLeft: 0
    },
    auto_close: {
        marginTop: 30,
        marginRight: 10
    },
    loading_center: {
        position: 'absolute',
        left: '50%',
        top: '50%',
        zIndex: 999
    }
});

export default withRouter(withStyles(styles)(OrderDetail));
