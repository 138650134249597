import React from 'react';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Icon from '@material-ui/core/Icon';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Badge from '@material-ui/core/Badge';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { ToastProvider } from 'react-toast-notifications';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import DashboardIcon from '@material-ui/icons/Dashboard';
import BarChartIcon from '@material-ui/icons/BarChart';

import cs from '../const.js';
import Dashboard from './Dashboard';
import DailyDashboard from './reports/DailyDashboard';
import MonthlyDashboard from './reports/MonthlyDashboard';

import ReadyToDeliveryToHub from './orders/ReadyToDeliveryToHub';
import OnDeliveryFromAgent from './orders/OnDeliveryFromAgent';
import ReceivedFromAgent from './orders/ReceivedFromAgent';
import HubApproved from './orders/HubApproved';
import HubSupplementRequest from './orders/HubSupplementRequest';
import OnDeliveryFromHub from './orders/OnDeliveryFromHub';
import ReceivedFromHub from './orders/ReceivedFromHub';
import BankSupplementRequest from './orders/BankSupplementRequest';
import BankApproved from './orders/BankApproved';

import WaitingForApproveOrders from './orders/WaitingForApproveOrders';
import SupplementRequestOrders from './orders/SupplementRequestOrders';
import WaitingMerchantOrders from './orders/WaitingMerchantOrders';
import MerchantApprovalOrders from './orders/MerchantApprovalOrders';

import OrderDetail from './order/OrderDetail';

import ChangePassword from './auth/ChangePassword';

const revokeTokenURL = cs.BaseURL + '/auth/logout';

const drawerWidth = 320;

const styles = (theme) => ({
    root: {
        display: 'flex',
        marginTop: 0,
        paddingTop: 10
    },
    toolbar: {
        paddingRight: 24, // keep right padding when drawer closed
        backgroundColor: 'rgb(68, 126, 236)'
    },
    toolbarHeader: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 2px',
        ...theme.mixins.toolbar
    },
    username: {
        marginRight: 20
    },
    avatar: {
        marginRight: 20
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        })
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        })
    },
    menuButton: {
        marginLeft: 12,
        marginRight: 36
    },
    menuButtonHidden: {
        display: 'none'
    },
    title: {
        flexGrow: 1
    },
    drawerPaper: {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        })
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        }),
        width: theme.spacing.unit * 7,
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing.unit * 9
        }
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        paddingTop: 0,
        paddingLeft: theme.spacing.unit * 2,
        paddingRight: theme.spacing.unit * 2,
        height: '100vh',
        overflow: 'auto'
    },
    chartContainer: {
        marginLeft: -22
    },
    tableContainer: {
        height: 320
    },
    h5: {
        marginBottom: theme.spacing.unit * 2
    }
});

class Home extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: true,
            showCreateOrder: false,
            showChangePassword: false
        };
    }

    handleDrawerOpen = () => {
        this.setState({ open: true });
    };

    handleDrawerClose = () => {
        this.setState({ open: false });
    };
    handleLogOut = () => {
        let queryString = `${revokeTokenURL}`;
        fetch(queryString, {
            method: 'POST',
            body: JSON.stringify({}),
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: localStorage.getItem(cs.System_Code + '-doc-token')
            }
        })
            .then((response) => response.json())
            .then((data) => {
                sessionStorage.clear();
                localStorage.clear();
                window.location.reload();
            })
            .catch(() => {
                sessionStorage.clear();
                localStorage.clear();
                window.location.reload();
            });

        sessionStorage.clear();
        localStorage.clear();
        window.location.reload();
    };

    isSysHR = () => {
        var user = localStorage.getItem(cs.System_Code + '-doc-user');
        if (user) return JSON.parse(String(user)).name == 'syshr';
    };

    handleShowChangePassword = () => {
        this.setState({ showChangePassword: true });
    };

    handleCloseChangePassword = () => {
        this.setState({ showChangePassword: false });
    };

    render() {
        const { classes } = this.props;

        var username = '';
        var user = localStorage.getItem(cs.System_Code + '-doc-user');
        if (user) username = JSON.parse(String(user)).name;

        const isDocHubAcc = () => {
            var user = localStorage.getItem(cs.System_Code + '-doc-user');
            if (!user) return false;
            var userrole = JSON.parse(String(user)).role;
            return (
                userrole == cs.Role_DOC_Hub_User ||
                userrole == cs.Role_DOC_Hub_Leader ||
                userrole == cs.Role_DOC_Hub_Manager
            );
        };
        const isDocBankAcc = () => {
            var user = localStorage.getItem(cs.System_Code + '-doc-user');
            if (!user) return false;
            var userrole = JSON.parse(String(user)).role;
            return (
                userrole == cs.Role_DOC_Bank_User ||
                userrole == cs.Role_DOC_Bank_Leader ||
                userrole == cs.Role_DOC_Bank_Manager
            );
        };

        const mainListItems = (
            <div>
                <ListItem button component={Link} to="/report/daily">
                    <ListItemIcon>
                        <Icon color="disable" style={{ marginLeft: 6, fontSize: 26 }}>
                            bar_chart
                        </Icon>
                    </ListItemIcon>
                    <ListItemText primary="Thống kê ngày" />
                </ListItem>

                <ListItem button component={Link} to="/report/monthly">
                    <ListItemIcon>
                        <Icon color="disable" style={{ marginLeft: 6, fontSize: 26 }}>
                            bar_chart
                        </Icon>
                    </ListItemIcon>
                    <ListItemText primary="Thống kê tháng" />
                </ListItem>
                <Divider />
                {/* <ListItem button component={Link} to="/document">
                    <ListItemIcon>
                        <Icon color="disable" style={{ marginLeft: 6, fontSize: 26 }}>
                            supervised_user_circle
                        </Icon>
                    </ListItemIcon>
                    <ListItemText primary="Nhân viên (DSA)" />
                </ListItem> */}

                {(isDocHubAcc() || !isDocBankAcc()) && (
                    <ListItem button component={Link} to="/readyToDeliveryToHub">
                        <ListItemIcon>
                            <Icon color="disable" style={{ marginLeft: 6, fontSize: 26 }}>
                                query_builder
                            </Icon>
                        </ListItemIcon>
                        <ListItemText primary="Hồ sơ DSA chưa bàn giao" />
                    </ListItem>
                )}

                {(isDocHubAcc() || !isDocBankAcc()) && (
                    <ListItem button component={Link} to="/onDeliveryFromAgent">
                        <ListItemIcon>
                            <Icon color="disable" style={{ marginLeft: 6, fontSize: 26 }}>
                                update
                            </Icon>
                        </ListItemIcon>
                        <ListItemText primary="Hồ sơ DSA đang chuyển về" />
                    </ListItem>
                )}

                {(isDocHubAcc() || !isDocBankAcc()) && (
                    <ListItem button component={Link} to="/receivedFromAgent">
                        <ListItemIcon>
                            <Icon color="disable" style={{ marginLeft: 6, fontSize: 26 }}>
                                check_circle
                            </Icon>
                        </ListItemIcon>
                        <ListItemText primary="Hồ sơ DSA đã bàn giao" />
                    </ListItem>
                )}

                {(isDocHubAcc() || !isDocBankAcc()) && (
                    <ListItem button component={Link} to="/hubApproved">
                        <ListItemIcon>
                            <Icon color="disable" style={{ marginLeft: 6, fontSize: 26 }}>
                                check_circle
                            </Icon>
                        </ListItemIcon>
                        <ListItemText primary="Hồ sơ đầy đủ" />
                    </ListItem>
                )}

                {/* <ListItem button component={Link} to="/waiting">
                    <ListItemIcon>
                        <Icon color="disable" style={{ marginLeft: 6, fontSize: 26 }}>
                            query_builder
                        </Icon>
                    </ListItemIcon>
                    {isDocHubAcc() || !isDocBankAcc() ? (
                        <ListItemText primary="Hồ sơ DSA bàn giao" />
                    ) : (
                        <ListItemText primary="Hồ sơ Hub bàn giao" />
                    )}
                </ListItem> */}

                {(isDocHubAcc() || !isDocBankAcc()) && (
                    <ListItem button component={Link} to="/hubSupplementRequest">
                        <ListItemIcon>
                            <Icon color="disable" style={{ marginLeft: 6, fontSize: 26 }}>
                                query_builder
                            </Icon>
                        </ListItemIcon>
                        <ListItemText primary="Hồ sơ chưa đầy đủ" />
                    </ListItem>
                )}

                <ListItem button component={Link} to="/onDeliveryFromHub">
                    <ListItemIcon>
                        <Icon color="disable" style={{ marginLeft: 6, fontSize: 26 }}>
                            update
                        </Icon>
                    </ListItemIcon>
                    <ListItemText primary="Hồ sơ đang chuyển đến Bank" />
                </ListItem>

                <ListItem button component={Link} to="/receivedFromHub">
                    <ListItemIcon>
                        <Icon color="disable" style={{ marginLeft: 6, fontSize: 26 }}>
                            check_circle
                        </Icon>
                    </ListItemIcon>
                    <ListItemText primary="Hồ sơ đã bàn giao cho Bank" />
                </ListItem>

                <ListItem button component={Link} to="/bankSupplementRequest">
                    <ListItemIcon>
                        <Icon color="disable" style={{ marginLeft: 6, fontSize: 26 }}>
                            cancel
                        </Icon>
                    </ListItemIcon>
                    <ListItemText primary="Hồ sơ Bank đánh giá thiếu" />
                </ListItem>

                <Divider variant="middle" />
                <ListItem button component={Link} to="/bankApproved">
                    <ListItemIcon>
                        <Icon color="disable" style={{ marginLeft: 6, fontSize: 26 }}>
                            check_circle
                        </Icon>
                    </ListItemIcon>
                    <ListItemText primary="Hồ sơ Bank đánh giá đạt" />
                </ListItem>
            </div>
        );

        const DailyDashboardArea = () => (
            <div>
                <Typography component="div" className={classes.tableContainer}>
                    <DailyDashboard />
                </Typography>
            </div>
        );

        const MonthlyDashboardArea = () => (
            <div>
                <Typography component="div" className={classes.tableContainer}>
                    <MonthlyDashboard />
                </Typography>
            </div>
        );

        const DocumentArea = () => (
            <div>
                <Typography variant="h5" gutterBottom component="h2">
                    Quản lý Hồ Sơ
                </Typography>
                <div className={classes.tableContainer}>{/* <AgentList /> */}</div>
            </div>
        );

        const ReadyToDeliveryToHubArea = () => (
            <div>
                <Typography variant="h5" gutterBottom component="h2">
                    Hồ sơ DSA chưa bàn giao
                </Typography>
                <div className={classes.tableContainer}>
                    <ReadyToDeliveryToHub />
                </div>
            </div>
        );

        const OnDeliveryFromAgentArea = () => (
            <div>
                <Typography variant="h5" gutterBottom component="h2">
                    Hồ sơ DSA đang chuyển về
                </Typography>
                <div className={classes.tableContainer}>
                    <OnDeliveryFromAgent />
                </div>
            </div>
        );

        const ReceivedFromAgentArea = () => (
            <div>
                <Typography variant="h5" gutterBottom component="h2">
                    Hồ sơ DSA đã bàn giao
                </Typography>
                <div className={classes.tableContainer}>
                    <ReceivedFromAgent />
                </div>
            </div>
        );

        const HubApprovedArea = () => (
            <div>
                <Typography variant="h5" gutterBottom component="h2">
                    Hồ sơ đầy đủ
                </Typography>
                <div className={classes.tableContainer}>
                    <HubApproved />
                </div>
            </div>
        );


        const HubSupplementRequestArea = () => (
            <div>
                <Typography variant="h5" gutterBottom component="h2">
                    Hồ sơ chưa đầy đủ
                </Typography>
                <div className={classes.tableContainer}>
                    <HubSupplementRequest />
                </div>
            </div>
        );

        const OnDeliveryFromHubArea = () => (
            <div>
                <Typography variant="h5" gutterBottom component="h2">
                    Hồ sơ đang chuyển đến Bank
                </Typography>
                <div className={classes.tableContainer}>
                    <OnDeliveryFromHub />
                </div>
            </div>
        );

        const ReceivedFromHubArea = () => (
            <div>
                <Typography variant="h5" gutterBottom component="h2">
                    Hồ sơ đã bàn giao cho Bank
                </Typography>
                <div className={classes.tableContainer}>
                    <ReceivedFromHub />
                </div>
            </div>
        );

        const BankSupplementRequestArea = () => (
            <div>
                <Typography variant="h5" gutterBottom component="h2">
                    Hồ sơ Bank đánh giá thiếu
                </Typography>
                <div className={classes.tableContainer}>
                    <BankSupplementRequest />
                </div>
            </div>
        );

        const BankApprovedArea = () => (
            <div>
                <Typography variant="h5" gutterBottom component="h2">
                    Hồ sơ Bank đánh giá đạt
                </Typography>
                <div className={classes.tableContainer}>
                    <BankApproved />
                </div>
            </div>
        );

        // const WaitingForApproveArea = () => (
        //     <div>
        //         <Typography variant="h5" gutterBottom component="h2">
        //             Hồ sơ bản cứng chờ phê duyệt
        //         </Typography>
        //         <div className={classes.tableContainer}>
        //             <WaitingForApproveOrders />
        //         </div>
        //     </div>
        // );

        // const SupplementRequestOrdersArea = () => (
        //     <div>
        //         <Typography variant="h5" gutterBottom component="h2">
        //             Hồ sơ bản cứng yêu cầu bổ sung
        //         </Typography>
        //         <div className={classes.tableContainer}>
        //             <SupplementRequestOrders />
        //         </div>
        //     </div>
        // );

        // const WaitingMerchantOrdersArea = () => (
        //     <div>
        //         <Typography variant="h5" gutterBottom component="h2">
        //             Hồ sơ bản cứng chờ Bank phê duyệt
        //         </Typography>
        //         <div className={classes.tableContainer}>
        //             <WaitingMerchantOrders />
        //         </div>
        //     </div>
        // );

        // const MerchantApprovalOrdersArea = () => (
        //     <div>
        //         <Typography variant="h5" gutterBottom component="h2">
        //             Kết quả phê duyệt
        //         </Typography>
        //         <div className={classes.tableContainer}>
        //             <MerchantApprovalOrders />
        //         </div>
        //     </div>
        // );

        return (
            <Router>
                <div className={classes.root}>
                    <CssBaseline />
                    <AppBar
                        position="absolute"
                        className={classNames(
                            classes.appBar,
                            this.state.open && classes.appBarShift
                        )}
                    >
                        <Toolbar disableGutters={!this.state.open} className={classes.toolbar}>
                            <IconButton
                                color="inherit"
                                aria-label="Open drawer"
                                onClick={this.handleDrawerOpen}
                                className={classNames(
                                    classes.menuButton,
                                    this.state.open && classes.menuButtonHidden
                                )}
                            >
                                <MenuIcon />
                            </IconButton>
                            {isDocHubAcc() || !isDocBankAcc() ? (
                                <Typography
                                    variant="h5"
                                    color="inherit"
                                    noWrap
                                    className={classes.title}
                                >
                                    Quản trị Hồ sơ tại Hub
                                </Typography>
                            ) : (
                                    <Typography
                                        variant="h5"
                                        color="inherit"
                                        noWrap
                                        className={classes.title}
                                    >
                                        Quản trị Hồ sơ tại Bank
                                    </Typography>
                                )}
                            <Button
                                color="inherit"
                                iconStyle={{
                                    height: 200,
                                    width: 200,
                                    fontSize: '48px'
                                }}
                                aria-label="Đổi mật khẩu"
                                onClick={this.handleShowChangePassword}
                            >
                                <Icon>vpn_key</Icon>
                                <span style={{ marginLeft: 10 }}>Đổi mật khẩu</span>
                            </Button>

                            <Button
                                color="inherit"
                                iconStyle={{
                                    height: 200,
                                    width: 200,
                                    fontSize: '48px'
                                }}
                                aria-label="Đăng Xuất"
                                onClick={this.handleLogOut}
                            >
                                <Icon>exit_to_app</Icon>
                                <span style={{ marginLeft: 10 }}>Đăng Xuất</span>
                            </Button>
                        </Toolbar>
                    </AppBar>
                    <Drawer
                        variant="permanent"
                        classes={{
                            paper: classNames(
                                classes.drawerPaper,
                                !this.state.open && classes.drawerPaperClose
                            )
                        }}
                        open={this.state.open}
                    >
                        <div className={classes.toolbarHeader}>
                            {cs.System_Id === 1 ? (
                                <Avatar
                                    className={classes.avatar}
                                    src={require('../img/logo-app-trans.png')}
                                />
                            ) : (
                                <Avatar
                                    className={classes.avatar}
                                    src={require('../img/logo-small.png')}
                                />
                            )}
                            <Typography variant="h6" className={classes.username}>
                                {username}
                            </Typography>
                            <IconButton onClick={this.handleDrawerClose}>
                                <ChevronLeftIcon />
                            </IconButton>
                        </div>
                        <Divider />
                        <List>{mainListItems}</List>
                    </Drawer>
                    <main className={classes.content}>
                        <div className={classes.appBarSpacer} />
                        <Route exact path="/" component={DocumentArea} />
                        <Route path="/document" component={DocumentArea} />
                        <Route path="/report/daily" component={DailyDashboardArea} />
                        <Route path="/report/monthly" component={MonthlyDashboardArea} />
                        <Route path="/readyToDeliveryToHub" component={ReadyToDeliveryToHubArea} />
                        <Route path="/onDeliveryFromAgent" component={OnDeliveryFromAgentArea} />
                        <Route path="/receivedFromAgent" component={ReceivedFromAgentArea} />
                        <Route path="/hubApproved" component={HubApprovedArea} />
                        <Route path="/hubSupplementRequest" component={HubSupplementRequestArea} />
                        <Route path="/onDeliveryFromHub" component={OnDeliveryFromHubArea} />
                        <Route path="/receivedFromHub" component={ReceivedFromHubArea} />
                        <Route
                            path="/bankSupplementRequest"
                            component={BankSupplementRequestArea}
                        />
                        <Route path="/bankApproved" component={BankApprovedArea} />

                        <Route path="/detail/:orderCode" component={OrderDetail} />

                        <ToastProvider autoDismissTimeout={3000} placement="bottom-center">
                            <ChangePassword
                                open={this.state.showChangePassword}
                                onClose={this.handleCloseChangePassword}
                            />
                        </ToastProvider>
                    </main>
                </div>
            </Router>
        );
    }
}

Home.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Home);
